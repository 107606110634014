import Vue from "vue";
import VueRouter from "vue-router";
import VueCookies from "vue-cookies";
import store from '../store';
import {i18n} from '../assets/i18n/index';

Vue.use(VueRouter);

/**测试路由表 */
const testRoutes = [
    {
        path: "/test",
        name: "TestIndex",
        component: () => import("../views/test/Index.vue"),
        meta:{
            title:'测试组件',
            keepAlive: false,
        }
    },{
        path: "/test/calendar",
        name: "Calendar",
        component: () => import("../views/test/Calendar.vue"),
        meta:{
            title:'日历',
            keepAlive: false,
        }
    },
];

let indexRoutes = [
    /**首页路由 */
  {
    path: "/",
    redirect:'/engine/config', // /new
    meta:{
      title:'首页',
      keepAlive: false,
    }
  },
];
const defaultRoutes = [
  /**登录注册部分路由 */
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/system/Login.vue"),
    meta:{
      title: i18n.t('system.login_page_title'),
      keepAlive: false,
    }
  },{
    path: "/signup",
    name: "Signup",
    component: () => import("../views/system/Signup.vue"),
    meta:{
      title: i18n.t('system.signup_page_title'),
      keepAlive: false,
    }
  },{
    path: "/forget",
    name: "Forget",
    component: () => import("../views/system/Forget.vue"),
    meta:{
      title: i18n.t('system.forget_page_title'),
      keepAlive: false,
    }
  },{
    path: "/system/check",
    name: "SystemCheck",
    component: () => import("../views/system/Check.vue"),
    meta:{
      title: i18n.t('system.check_page_title'),
      keepAlive: false,
    }
  },
  /**关于部分路由 */
  {
    path: "/about/index",
    name: "About",
    component: () => import("../views/About.vue"),
    meta:{
      title:'关于',
      keepAlive: false,
    }
  },
];

const errorRoutes = [
    /**404部分路由 */
    {
        path:'/404',
        name:'Notfind',
        component:() => import("../views/404.vue"),
        meta:{
            title:'404',
            keepAlive: false,
        }
    },{
        path:'*',
        redirect:'/404'
    },
];

const routes = [
  /** 项目部分路由 */
  {
    path: "/project/index",
    name: "ProjectIndex",
    component: () => import("../views/project/Index.vue"),
    meta:{
      title: i18n.t('system.project_index_page_title'),
      keepAlive: false,
      permission: 'project',
    }
  },{
    path: "/project/list",
    name: "ProjectList",
    component: () => import("../views/project/List.vue"),
    meta:{
      title:'项目详情',
      keepAlive: false,
      permission: 'project:detailRouter',
    }
  },{
    path: "/project/taskStatistics",
    name: "ProjectTaskStatistics",
    component: () => import("../views/project/ProjectTaskStatistics.vue"),
    meta:{
      title:'项目任务统计',
      keepAlive: false,
      permission: 'project',
    }
  },{
    path: "/task/list/index",
    name: "TaskList",
    component: () => import("../views/project/Tasks.vue"),
    meta:{
      title:'我的任务',
      keepAlive: false,
      permission: 'tasks',
    }
  },{
    path: "/task/statistics/index",
    name: "TaskStatistics",
    component: () => import("../views/project/TaskStatistics.vue"),
    meta:{
      title:'任务统计',
      keepAlive: false,
      permission: '', //tasks
    }
  },
  /**新建项目路由 */
  { //个人用户创建入口
    path: "/project/new",
    name: "NewProject",
    component: () => import("../views/project/New.vue"),
    meta:{
      title:'新建项目',
      keepAlive: false,
      permission: 'project:newRouter',
    }
  },{//企业用户创建入口
    path: "/project/create",
    name: "CreateProject",
    component: () => import("../views/project/Create.vue"),
    meta:{
      title:'新建项目',
      keepAlive: false,
      permission: 'project:newRouter',
    }
  },
  /** 编辑器部分路由 */
  {
    path: "/workbench/editer",
    name: "Editer",
    component: () => import("../views/workbench/Editer.vue"),
    meta:{
      title:'编辑器',
      keepAlive: false,
      permission: 'project:editRouter',
    }
  },
  /** 语言资产部分路由 */
  {
    path: "/asset/term/index",
    name: "TermIndex",
    component: () => import("../views/assets/term/Index.vue"),
    meta:{
      title:'术语库',
      keepAlive: false,
      type: 'TERM',
      permission: 'memory:termStoreRouter',
    }
  },{
    path: "/asset/term/detail",
    name: "TermDetail",
    component: () => import("../views/assets/term/Detail.vue"),
    meta:{
      title:'术语库详情',
      keepAlive: false,
      type: 'TERM',
      permission: 'memory:termStore:detailRouter',
    }
  },{
    path: "/asset/memory/index",
    name: "MemoryIndex",
    component: () => import("../views/assets/memory/Index.vue"),
    meta:{
      title:'记忆库',
      keepAlive: true,
      type: 'MEM',
      permission: 'memory:memoryStoreRouter',
    }
  },{
    path: "/asset/memory/detail",
    name: "MemoryDetail",
    component: () => import("../views/assets/memory/Detail.vue"),
    meta:{
      title:'记忆库详情',
      keepAlive: false,
      type: 'MEM',
      permission: 'memory:memoryStore:detailRouter',
    }
  },
  /** 工具箱部分路由 */
  {
    path: "/tools",
    name: "ToolsIndex",
    component: () => import("../views/tools/Index.vue"),
    meta:{
      title: i18n.t('system.tools_page_title'),
      keepAlive: false,
    //   type: 'TOOLKIT_SA',
      permission: '',
    }
  },{
    path: "/tools/align/index",
    name: "ToolsAlignIndex",
    component: () => import("../views/tools/align/Index.vue"),
    meta:{
      title:'语料对齐',
      keepAlive: false,
      type: 'TOOLKIT_SA',
      permission: 'tools:alignmentRouter',
    }
  },{
    path: "/tools/align/detail",
    name: "ToolsAlignDetail",
    component: () => import("../views/tools/align/Detail.vue"),
    meta:{
      title:'语料对齐详情（AI）',
      keepAlive: false,
      type: 'TOOLKIT_SA',
      permission: 'tools:alignment:detailRouter',
    }
  },{
    path: "/tools/align/tiled",
    name: "ToolsAlignDetailTiled",
    component: () => import("../views/tools/align/Tiled.vue"),
    meta:{
      title:'语料对齐详情（规则）',
      keepAlive: false,
      type: 'TOOLKIT_SA',
      permission: 'tools:alignment:rolesRouter',
    }
  },{
    path: "/tools/termExtraction/index",
    name: "ToolsTermExtractionIndex",
    component: () => import("../views/tools/termExtraction/Index.vue"),
    meta:{
      title:'术语提取',
      keepAlive: false,
      type: 'TOOLKIT_TE',
      permission: 'tools:extractionRouter',
    }
  },{
    path: "/tools/termExtraction/detail",
    name: "ToolsTermExtractionDetail",
    component: () => import("../views/tools/termExtraction/Detail.vue"),
    meta:{
      title:'术语提取详情',
      keepAlive: false,
      type: 'TOOLKIT_TE',
      permission: 'tools:extraction:detailRouter',
    }
  },{
    path: "/tools/qa/index",
    name: "ToolsQAIndex",
    component: () => import("../views/tools/qa/Index.vue"),
    meta:{
      title:'QA检查',
      keepAlive: false,
      type: 'TOOLKIT_QA',
      permission: 'tools:checkRouter',
    }
  },{
    path: "/tools/qa/detail",
    name: "ToolsQADetail",
    component: () => import("../views/tools/qa/Detail.vue"),
    meta:{
      title:'QA检查详情',
      keepAlive: false,
      type: 'TOOLKIT_QA',
      permission: 'tools:check:detailRouter',
    }
  },{
    path: "/tools/qa/detail-new",
    name: "ToolsQADetail",
    component: () => import("../views/tools/qa/DetailNew.vue"),
    meta:{
      title:'QA检查详情',
      keepAlive: false,
      type: 'TOOLKIT_QA',
      permission: 'tools:check:detailRouter',
    }
  },{
    path: "/tools/detector/index",
    name: "ToolsDetectorIndex",
    component: () => import("../views/tools/detector/Index.vue"),
    meta:{
      title:'机翻检测器',
      keepAlive: false,
      type: 'TOOLKIT_MTI',
      permission: 'tools:detectorRouter',
    }
  },{
    path: "/tools/detector/detail",
    name: "ToolsDetectorDetail",
    component: () => import("../views/tools/detector/Detail.vue"),
    meta:{
      title:'机翻检测结果',
      keepAlive: false,
      type: 'TOOLKIT_MTI',
      permission: 'tools:detector:detailRouter',
    }
  },{
    path: "/tools/polish/index",
    name: "ToolsPolishIndex",
    component: () => import("../views/tools/polish/Index.vue"),
    meta:{
      title:'AI润色',
      keepAlive: false,
    //   type: 'TOOLKIT_MTI',
      permission: 'tools:polishRouter',
    }
  },{
    path: "/tools/videoTranslate/index",
    name: "ToolsVideoTranslateIndex",
    component: () => import("../views/tools/videoTranslate/Index.vue"),
    meta:{
      title:'AI译配',
      keepAlive: false,
      type: 'TOOLKIT_VT',
      permission: 'tools:videoTranslateRouter',
    }
  },{
    path: "/tools/videoTranslate/editer",
    name: "ToolsVideoTranslateEditer",
    component: () => import("../views/tools/videoTranslate/Editer.vue"),
    meta:{
      title:'AI译配 - 字幕编辑',
      keepAlive: false,
      type: 'TOOLKIT_VT',
      permission: '',
    }
  },{
    path: "/agent/gptTranslate/index",
    name: "AgentGptTranslateIndex",
    component: () => import("../views/tools/gptTranslate/Index.vue"),
    meta:{
      title:'文档智能体',
      keepAlive: false,
      type: '',
      permission: '',// tools:gptTranslateRouter
    }
  },{
    path: "/agent/gptTranslate/history",
    name: "AgentGptTranslateHistory",
    component: () => import("../views/tools/gptTranslate/History.vue"),
    meta:{
      title:'文档智能体',
      keepAlive: false,
      type: '',
      permission: '',
    }
  },{
    path: "/tools/translationScore/index",
    name: "ToolsTranslationScoreIndex",
    component: () => import("../views/tools/translationScore/Index.vue"),
    meta:{
      title:'AI翻译评分',
      keepAlive: false,
      type: '',
      permission: '',
    }
  },
  /** 我的翻译引擎 */
  {
    path: "/engine/index",
    name: "EngineIndex",
    component: () => import("../views/engine/Index.vue"),
    meta:{
      title:'我的翻译引擎',
      keepAlive: false,
      type: 'MT_CORPUS',
      permission: 'engine:engineIndexRouter',
    }
  },{
    path: "/engine/create",
    name: "EngineCreate",
    component: () => import("../views/engine/Create.vue"),
    meta:{
      title: i18n.t('engine.create_page_title'),
      keepAlive: false,
      type: 'MT_CORPUS',
      permission: 'engine:engineCreateRouter',
    }
  },{
    path: "/engine/quickCreate",
    name: "EngineQuickCreate",
    component: () => import("../views/engine/QuickCreate.vue"),
    meta:{
      title:'快速训练',
      keepAlive: false,
      type: 'MT_CORPUS',
      permission: 'engine:engineQuickCreateRouter',
    }
  },{
    path: "/engine/ragCreate",
    name: "EngineRagCreate",
    component: () => import("../views/engine/RagCreate.vue"),
    meta:{
      title: i18n.t('engine.ragCreate_page_title'),
      keepAlive: false,
      type: 'MT_CORPUS',
      permission: 'engine:engineQuickCreateRouter',
    }
  },{
    path: "/engine/config",
    name: "EngineConfig",
    component: () => import("../views/engine/Config.vue"),
    meta:{
      title: i18n.t('engine.config_page_title'),
      keepAlive: false,
      type: 'MT_CORPUS',
      permission: 'engine',
    }
  },{
    path: "/engine/config-new",
    name: "EngineConfigNew",
    component: () => import("../views/engine/ConfigNew.vue"),
    meta:{
      title:'翻译引擎',
      keepAlive: false,
      type: 'MT_CORPUS',
      permission: 'engine',
    }
  },{
    path: "/engine/detail",
    name: "EngineDetail",
    component: () => import("../views/engine/Detail.vue"),
    meta:{
      title:'引擎详情',
      keepAlive: false,
      type: 'MT_CORPUS',
      permission: 'engine:engineDetailRouter',
    }
  },{
    path: "/engine/s/:id/:token",
    name: "EngineShare",
    component: () => import("../views/engine/Share.vue"),
    meta:{
      title:'引擎分享',
      keepAlive: false,
      permission: 'engine:engineShareRouter',
    }
  },
  /** 团队管理部分路由 */
  {
    path: "/team/index",
    name: "TeamIndex",
    component: () => import("../views/team/IndexNew.vue"),
    meta:{
      title:'团队管理',
      keepAlive: false,
      permission: 'team',
    }
  },{
    path: "/team/index-inside",
    name: "TeamIndex",
    component: () => import("../views/team/IndexInside.vue"),
    meta:{
      title:'团队管理',
      keepAlive: false,
      permission: '',
    }
  },{
    path: "/team/invite/:code",
    name: "TeamInvite",
    component: () => import("../views/team/Invite.vue"),
    meta:{
      title:'邀请加入团队',
      keepAlive: false,
      permission: 'team:applyInviteRouter',
    }
  },
  /** 账户中心路由 */
  {
    path: "/account",
    component: () => import("../views/account/AccountRouter.vue"),
    meta: { title: '个人中心',keepAlive: false, },
    redirect:'/account/vip',
    children: [{
            path: "index",
            name: "AccountIndex",
            component: () => import("../views/account/Index.vue"),
            meta:{
                title:'我的账户',
                keepAlive: false,
                permission: 'account',
            }
        },{
            path: "vip",
            name: "AccountVip",
            component: () => import("../views/account/Vip.vue"),
            meta:{
                title:'我的会员',
                keepAlive: false,
                permission: 'account:VIPRouter',
            }
        },{
            path: "orders",
            name: "AccountOrders",
            component: () => import("../views/account/Orders.vue"),
            meta:{
                title:'我的订单',
                keepAlive: false,
                permission: 'account:ordersRouter',
            }
        },{
            path: "invite",
            name: "AccountInvite",
            component: () => import("../views/account/Invite.vue"),
            meta:{
                title:'邀请好友',
                keepAlive: false,
                permission: 'account:inviteRouter',
            }
        },{
            path: "chars",
            name: "AccountChars",
            component: () => import("../views/account/Chars.vue"),
            meta:{
                title:'流量列表',
                keepAlive: false,
                permission: 'account:charsRouter',
            }
        },{
            path: "charHistory",
            name: "AccountCharHistory",
            component: () => import("../views/account/CharHistory.vue"),
            meta:{
                title:'流量监测',
                keepAlive: false,
                permission: 'account:charHistoryRouter',
            }
        },{
            path: "orgInfo",
            name: "AccountOrgInfo",
            component: () => import("../views/account/OrgInfo.vue"),
            meta:{
                title:'企业信息',
                keepAlive: false,
                permission: 'account:orgInfoRouter',
            }
        },{
            path: "agentToken",
            name: "AccountAgentToken",
            component: () => import("../views/account/AgentToken.vue"),
            meta:{
                title:'智能体Token',
                keepAlive: false,
                permission: '',
            }
        },
    ], 
  },

];



const filterRouter = (arr, routerPermissions) => {
    // console.log('routerPermissions::::', routerPermissions);
    let result = [];
    arr.map(async (route)=>{
        let _route = route;
        if(!route.meta.permission || (route.meta.permission && routerPermissions.indexOf(route.meta.permission) > -1)) {
            if(route.children && route.children.length) {
                let _children = filterRouter(route.children, routerPermissions);
                _route.children = _children;
                if(route.redirect && _children.length){
                    _route.redirect = `${route.path}/${_children[0].path}`;
                }
            }
            result.push(_route);
        }
    })
    return result;
}

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [].concat(defaultRoutes, ['development', 'beta'].includes(process.env.NODE_ENV) ? testRoutes : []),
});

let isLogin = localStorage.getItem('userInfo') !==null && VueCookies.get('token')!==null;
if (isLogin){
    store.dispatch("user/initUserPermissions").then(res => {
        let result = filterRouter(routes, res.router)
        const permissionData = res.data;
        if(permissionData[0].componentPath){
            indexRoutes[0].redirect = permissionData[0].componentPath;
        }
        router.addRoutes(indexRoutes);
        router.addRoutes(result);
        router.addRoutes(errorRoutes);
        
    }).finally(()=>{
        // console.log('[INFO]', 'system reuter：', router.getRoutes())
    });

}


export default router;
