<template>
    <el-dialog :title="$t('component.BindWechat_title')" :visible.sync="showBindWechat" destroy-on-close @open="getWechatQrcode" @close="closeDialogEvent" width="440px" top="20px">  
        <div class="bind-wechat-form-content">
            <div class="fonts-14">{{ $t('component.BindWechat_scan_qrcode_title') }}</div>
            <div class="image-content">
                <img :src="wechat.qrcodeUrl" alt="wechart-qrcode" v-if="wechat"/>
                <div class="cover" v-if="expired">
                    <svg-icon name="ib-reload-right" className="fonts-24"></svg-icon>
                    <a class="margin-t-4" href="javascript:;" @click="getWechatQrcode">{{ $t('component.BindWechat_button_refresh') }}</a>
                    <div class="margin-t-10 color-666666">{{ $t('component.BindWechat_qrcode_overdue_tips') }}</div>
                </div>
                
            </div>
            <div class="tips">{{ $t('component.BindWechat_tips_1') }}<br>{{ $t('component.BindWechat_tips_2') }}</div>
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
import { userApi } from '@/utils/api';
export default {
	components: {  },
    name: "ChangeNickname",
    data() {
        return {
            timer: null,
            wechat: null,
            expired: false,
        }   
    },
    computed: {
		...mapState({
			userInfo: state => state.user.userData,
            showBindWechat: state => state.user.showBindWechat,
		}),
	},
    methods: {
        closeDialogEvent(){
           this.$store.dispatch('user/setBindWechatVisible', false);
           if (this.timer){
                clearInterval(this.timer);
                this.timer = null;
           }
           if(this.userInfo.popWindows){
                this.clearFirstLoginFlag();
           }
        },
        getWechatQrcode(){
            let url = userApi.getWechatQrcode;
            this.$ajax.get(url).then(res => {
                if (res.status === 200){
                    this.expired = false;
                    this.wechat = {
                        // ...this.wechat,
                        ...res.data,
                    };
                    this.startTimer();
                }
            })
        },
        startTimer(){
            let self = this;
            self.getWechatStatus();
            this.timer = setInterval(() => {
                self.getWechatStatus();
            }, 2000);
        },
        getWechatStatus(){
            let url = `${userApi.pollingWechatQRStatus}?isLogin=true&eventKey=${this.wechat.eventKey}`;
            this.$ajax.get(url).then(res => {
                if (res.status === 200){
                    let data = res.data;
                    switch (data.status){
                        case 'expire': //二维码已失效
                            this.expired = true;
                            if (this.timer){
                                clearInterval(this.timer);
                                this.timer = null;
                            }
                            break;
                        case 'bound': //登录成功
                            this.$store.dispatch('user/setBindWechatVisible', false);
                            this.$message.success(this.$t('component.BindWechat_bind_success_message'));
                            this.$nextTick(()=>{
                                this.$router.go(0);
                            });
                            break;
                        
                    }
                }
            }).catch(e=>{
                if (this.timer){
                    clearInterval(this.timer);
                    this.timer = null;
                    this.getWechatQrcode();
                }
            });
        },
        clearFirstLoginFlag(){
            let url = userApi.clearFirstWindow;
            this.$ajax.post(url, {firstTimeLogin: 0}).then(res => {});
        }
    },
    created() {
       
    },
    mounted() {
        
    },
    beforeDestroy() {
        if (this.timer){
            clearInterval(this.timer);
            this.timer = null;
        }
        window.removeEventListener('beforeunload', this.closeDialogEvent);
    },
    watch: {
        '$store.state.user.showBindWechat'(newVal, oldVal){
            if(newVal){
                window.addEventListener('beforeunload', this.closeDialogEvent);
            }
        }, 
    },

}
</script>
<style lang="scss" scoped>
.bind-wechat-form-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
    color: #666666;
    .image-content{
        width: 200px;
        height: 200px;
        margin-top: 20px;
        // padding: 12px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        position: relative;
        .cover{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            background: rgba(255, 255, 255, 0.8);
            backdrop-filter: blur(3.5px);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            cursor: pointer;
        }
        img{
            width: 100%;
        }
    }
    .tips {
        margin-top: 20px;
        font-size: 12px;
        line-height: 17px;
        color: #666666;
        text-align: center;
    }
}
</style>
