/**购买相关状态 */



const state=()=>({
    payProDialog:{
        show: false,
    },
    payErpDialog:{
        show: false,
    },
    charDialog: {
        show: false,
        type: 'c10',
        title: '字符包',
    },
    payTokenDialog: {
        show: false,
        type: '',
    },
    
})

const getters={

}

const actions ={
    setPayProDialogAction({commit},data){
        commit('SET_PAY_PRO_DIALOG',data)
    },
    setCharDialog({commit},data){
        commit('SET_CHAR_DIALOG',data)
    },
    setPayErpDialogAction({commit}, data){
        commit('SET_PAY_ERP_DIALOG',data)
    },
    setPayTokenDialog({commit}, data){
        commit('SET_PAY_TOKEN_DIALOG',data)
    },
}

const mutations = {
    SET_PAY_PRO_DIALOG(state, payload){
        state.payProDialog = {
            ...state.payProDialog,
            ...payload
        };
    },
    SET_CHAR_DIALOG(state, payload){
        state.charDialog = {
            ...state.charDialog,
            ...payload
        };
    },
    SET_PAY_ERP_DIALOG(state, payload){
        state.payErpDialog = {
            ...state.payErpDialog,
            ...payload
        };
    },
    SET_PAY_TOKEN_DIALOG(state, payload){
        state.payTokenDialog = {
            ...state.payTokenDialog,
            ...payload
        }
    }
}

export default{
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
