<template>
    <el-dialog :title="$t('component.PayProDialog_title')" :visible.sync="payDialog.show" destroy-on-close @open="openPayDialogEvent" @close="closePayDialogEvent" width="872px" top="20px">
        <div class="pay-pro-success" v-if="status === 'paid'">
            <i class="el-icon-success"></i>
            <div class="fonts-20 margin-t-10">{{ $t('component.PayProDialog_success_message_1') }}</div>
            <div class="fonts-14 margin-t-10">{{ $t('component.PayProDialog_success_message_2') }}</div>
            <img src="../../static/images/tencent-group-qrcode.png" alt="qrcode"/>
            <div class="fonts-14 margin-t-10">{{ $t('component.PayProDialog_success_message_3') }}</div>
            <div class="text-center margin-t-30">
                <el-button plain round size="small" @click="applyInvoiceEvent"><svg-icon name="ib-invoicing"></svg-icon> {{ $t('component.PayProDialog_button_apply_invoice') }}</el-button>
            </div>
        </div>
        <div class="pay-pro-dialog" v-loading="loading" v-else>
            <div class="pay-type-content">
                <template v-for="item in priceOptions">
                    <div :class="['item', active === item.code ? 'active' : '']" :key="item.code" @click="changePayTypeEvent(item.code)">
                        <div class="text-weight-600 fonts-18 margin-b-10">{{item.desc}}</div>
                        <div class="fonts-14 color-919191 margin-b-20">{{item.subDesc}}</div>
                        <div class="fonts-28"><span class="text-weight-700">{{ $t('component.PayProDialog_currency_symbol') }}{{item.price}}/</span><span class="fonts-16 text-weight-700">{{item.timeUnit}}</span><span class="fonts-18 color-919191 line-through margin-l-10">{{ $t('component.PayProDialog_currency_symbol') }}{{item.originalPrice}}/{{item.timeUnit}}</span></div>
                        <div class="tag">{{ $t('component.PayProDialog_label_time_limit') }}</div>
                    </div>
                </template>
            </div>
            <div class="pay-detail-content" >
                <template v-if="order">
                    <div class="qrcode">
                        <img class="width-full" :src="order.codeImgUrl" alt="qrcode"/>
                    </div>
                    <div class="fonts-12" style="padding-left:30px;">
                        <div>{{ $t('component.PayProDialog_form_orderNo_label') }}<span class="margin-l-10">{{order.orderNo}}</span></div>
                        <div class="margin-t-15">{{ $t('component.PayProDialog_form_amount_label') }}<span class="margin-l-10 fonts-24 text-weight-500">{{ $t('component.PayProDialog_currency_symbol') }}{{order.amount}}</span></div>
                        <div class="margin-t-15 flex flex-align-center">{{ $t('component.PayProDialog_form_scan_label') }}<svg-icon name="ic-wechat-pay" className="margin-l-10" style="width: 22px;height: 22px;"></svg-icon><span class="margin-l-5">{{ $t('component.PayProDialog_form_scan_platform_wechat') }}</span></div>
                        <div class="margin-t-15"><router-link to="/account/vip" target="_blank">{{ $t('component.PayProDialog_form_org_benefits_tips') }}</router-link></div>
                    </div>
                </template>
            </div>
            <div class="fonts-12 margin-t-25 text-center">{{ $t('component.PayProDialog_bottom_tips_1') }} <a href="http://static.languagex.com/page/serviceTerms.html" target="_blank">{{ $t('component.PayProDialog_bottom_tips_2') }}</a> {{ $t('component.PayProDialog_bottom_tips_3') }} <a href="http://static.languagex.com/page/privacyPolicy.html" target="_blank">{{ $t('component.PayProDialog_bottom_tips_4') }}</a></div>
        </div>
        
    </el-dialog>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { userApi, payApi } from '@/utils/api';
export default {
    data () {
        return {
            priceOptions: [],
            active: 'monthly', //yearly
            order: null,
            loading: false,
            orderStatusTimer: null,
            status: '',
        }   
    },
    computed: {
        ...mapState({
            payDialog: state => state.pay.payProDialog,
        })
    },
    
    methods: {
        initPriceOptions(){
            let url = `${payApi.queryPriceOptions}?type=PRO`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.priceOptions = res.data;
                    this.active = this.priceOptions[0].code;
                    // this.startTime = moment(new Date()).format('yyyy-MM-DD');
                    // this.queryEndTime();
                    this.initOrder();
                }
            });
        },
        closePayDialogEvent(){
            this.active = 'monthly';
            this.status = '';
            this.$store.dispatch('pay/setPayProDialogAction',{show: false});
            clearInterval(this.orderStatusTimer);
            this.orderStatusTimer = null;
            /* setTimeout(()=>{
                location.reload(true);
            }, 800); */
        },
        openPayDialogEvent(){
            this.initPriceOptions();
        },
        initOrder(){
            let self = this;
            let url = `${payApi.createOrder}`;
            this.loading = true;
            this.$ajax.post(url,{
                method: this.active,
                channel: 'wechat',
            }).then(res=>{
                if(res.status === 200){
                    this.order = res.data;
                    if(this.orderStatusTimer){
                        clearInterval(this.orderStatusTimer);
                        this.orderStatusTimer = null;
                    }
                    this.orderStatusTimer = setInterval(() =>{
                        self.pollingOrderStatus();
                    }, 1000);
                }
            }).finally(()=>{
                this.loading = false;
            })
        },
        changePayTypeEvent(type){
            this.active = type;
            this.initOrder();
        },
        pollingOrderStatus(){
            let url = `${payApi.queryOrder}?orderNo=${this.order.orderNo}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    let data = res.data;
                    if(data.orderStatus === 'paid'){
                        this.status = data.orderStatus;
                        // this.$message.success("支付成功!");
                        clearInterval(this.orderStatusTimer);
                        this.orderStatusTimer = null;
                        //用户信息
                        this.$store.dispatch('user/queryUserInfoAction');
                        //我的机构信息
                        this.$store.dispatch('org/queryMyOrgList');
                    }else if(data.orderStatus === 'failed'){
                        this.$message.success($t('component.PayProDialog_pay_fail_message'));
                        this.initOrder();
                    }
                }
            })
        },
        applyInvoiceEvent() {
            this.closePayDialogEvent();
            window.open('https://m15er90ta0.feishu.cn/share/base/form/shrcnt4Z1ziC7IVeP7AuTq8ffDc', '_blank');
        },
    },
    mounted(){
        window.addEventListener('beforeunload', e => {
            this.closePayDialogEvent();
        });
    },
    beforeDestroy(){
        window.removeEventListener('beforeunload');
    }
    
}
</script>
<style lang="scss" scoped>
.pay-pro-dialog {
    margin: 10px 0;
    min-height: 326px;
    .pay-type-content{
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        .item{
            position: relative;
            padding: 20px;
            width: 250px;
            margin-right: 20px;
            border: 2px solid transparent;
            box-sizing: border-box;
            border-radius: 12px;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
            cursor: pointer;
            &:last-child{
                margin-right: 0;
            }
            &.active{
                border-color: #00D959;
            }
            .tag{
                border-radius: 0 12px 0 12px;
                background-color: #F56C6C;
                // width: 48px;
                padding: 0 10px;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 500;
                font-size: 12px;
                color: #FFFFFF;
                position: absolute;
                top: -2px;
                right: -2px;
            }
        }
    }
    .pay-detail-content{
        width: 407px;
        height: 170px;
        padding: 0 26px;
        margin: 0 auto;
        background-color: #FFFFFF;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        .qrcode{
            width: 140px;
            height: 140px;
        }
    }
}
.pay-pro-success{
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    i {
        font-size: 44px;
        color: #00B670;
    }
    img {
        margin-top: 40px;
        width: 140px;
    }

}
</style>
