<template>
    <div class="breadcrumb-component">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>活动管理</el-breadcrumb-item>
            <el-breadcrumb-item>活动列表</el-breadcrumb-item>
            <el-breadcrumb-item>活动详情</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>
<script>
export default {
    data(){
        return {

        }
    }
}
</script>
<style lang="scss" scoped>

</style>

<style lang="scss">
.breadcrumb-component{
    padding-bottom:20px;
    .el-breadcrumb__separator[class*=icon]{
        margin: 0 14px;
        color:#000000;
    }
    .el-breadcrumb__inner.is-link, .el-breadcrumb__inner a{
        font-weight: 500;
        color: #000000;
        font-size: 16px;
    }
    .el-breadcrumb__inner{
        color: #00000099;
    }
    .el-breadcrumb__item:last-child .el-breadcrumb__inner{
        color: #00000099;
        font-size: 15px;
        
    }
}


</style>
