import Vue from "vue";
import Vuex from "vuex";
import { createVuexPersistedState } from "vue-persistedstate";
import common from './modules/common';
import user from './modules/user';
import org from './modules/org';
import editer from './modules/editer';
import pay from './modules/pay';
import download from "./modules/download";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    common,
    user,
    org,
    editer,
    pay,
    download,
  },
  plugins: [
    createVuexPersistedState(),
  ]
});
