<template>
    <el-dropdown trigger="click" placement="right" v-if="userInfo">
        <div class="user-avatar-tigger-btn">
            <div class="relative">
                <el-avatar :size="32" :icon="activeOrg.organizationType === 'ENTERPRISE' ? 'el-icon-suitcase' : 'el-icon-user'" :src="userInfo.avatar"></el-avatar>
                <div class="vip-icon">
                    <vip-icon :size="14" :type="activeOrg.accountType"></vip-icon>
                </div>
            </div>
            <!-- <div class="name">{{activeOrg.organizationType === 'ENTERPRISE' ? activeOrg.organizationName : org.name}}</div> -->
        </div>
        <el-dropdown-menu class="user-avatar-dropdown" slot="dropdown" v-if="userInfo">
            <div class="flex flex-align-center">
                <el-avatar :size="44" :src="userInfo.avatar" icon="el-icon-user" style="font-size: 28px;"></el-avatar>
                <div class="margin-l-10 min-w-0 flex-1">
                    <div class="fonts-14 text-weight-600 text-omission">{{ activeOrg.name }}</div>
                    <div class="fonts-12 color-666666 margin-t-8">{{ activeOrg.organizationName }}</div>
                </div>
            </div>
            <div class="vip-wapper pro" @click="handlerCommand('goVip')">
                <div class="flex flex-align-center flex-justify-between">
                    <div class="flex flex-align-center">
                        <vip-icon :size="16" :type="activeOrg.organizationType === 'ENTERPRISE' ? 'enterprise' : userInfo.accountType"></vip-icon>
                        <span class="margin-l-4 text-weight-600 fonts-14">{{ activeOrg.accountTypeName }}</span>
                        <span class="margin-l-8 color-warning fonts-12" v-if="['personal', 'pro-limited'].indexOf(userInfo.accountType) < 0 && userInfo.expireTime">{{moment(userInfo.expireTime).format($t('component.UserAvatarMenu_expireTime_format'))}} {{$t('component.UserAvatarMenu_expireTime_label')}}</span>
                        <span class="margin-l-8 color-warning fonts-12" v-if="['personal', 'pro-limited'].indexOf(userInfo.accountType)>-1">{{ $t('component.UserAvatarMenu_upgrade_to_pro') }}</span>
                    </div>
                    <svg-icon :size="15" name="ib-arrow-right" class-name="color-666666" v-permission:delay="`account:VIPRouter`"></svg-icon>
                </div>
                <div class="margin-t-10 fonts-12" v-if="activeOrg.organizationType === 'ENTERPRISE' && userInfo.memberQuota">
                    <span>{{ $t('component.UserAvatarMenu_info_label_1') }}</span>
                    <span>{{userInfo.memberQuota.used}}</span>
                    <span class="color-999">/{{userInfo.memberQuota.limit}}</span>
                </div>
                <div class="margin-t-10 fonts-12" v-if="userInfo.flowQuota">
                    <span>{{ $t('component.UserAvatarMenu_info_label_2') }}</span>
                    <span>{{userInfo.flowQuota.surplus}}</span>
                    <span class="color-999">/{{userInfo.flowQuota.limit}}</span>
                </div>
            </div>
            <div class="menu-list">
                <div class="item" v-if="myOrgList.length > 1">
                    <el-popover placement="left" trigger="hover" :visible-arrow="false" class="width-full height-full" popper-class="transfer-wrapper">
                        <div slot="reference" class="flex flex-align-center width-full height-full">
                            <svg-icon name="ib-transfer" class-name="fonts-18 margin-l-15"></svg-icon>
                            <span class="margin-l-10 fonts-12">{{ $t('component.UserAvatarMenu_button_switch_org') }}</span>
                        </div>
                        <div class="transfer-org-list">
                            <template v-for="org in myOrgList">
                                <div class="item" :key="org.organizationId" @click="transferOrgEvent(org)" v-if="!org.isSelected">
                                    <div class="relative" style="height: 32px;">
                                        <el-avatar :size="32" :src="org.avatar" :icon="org.organizationType === 'ENTERPRISE'?'el-icon-suitcase':'el-icon-user'" ></el-avatar>
                                        <span class="absolute" style="bottom: -6px; right: 0">
                                            <vip-icon :size="12" :type="org.accountType"></vip-icon>
                                        </span>
                                    </div>
                                    <div class="margin-l-10 fonts-12 min-w-0">
                                        <div class="text-weight-600 text-omission">{{ org.organizationType === 'ENTERPRISE'? org.organizationName: org.name }}</div>
                                        <!-- <div class="color-666666 margin-t-2" v-if="org.organizationType === 'ENTERPRISE'">{{org.memberQuantity}} 成员</div> -->
                                    </div>
                                </div>
                            </template>
                        </div>
                    </el-popover>
                        
                </div>
                <div class="item" v-permission:delay="`account`" @click="handlerCommand('goUser')">
                    <svg-icon name="ib-user" class-name="fonts-18 margin-l-15"></svg-icon>
                    <span class="margin-l-10">{{ $t('component.UserAvatarMenu_button_user_center') }}</span>
                </div>
                <div class="item" v-permission:delay="`orgAccount`" @click="handlerCommand('goOrg')">
                    <svg-icon name="ib-center" class-name="fonts-18 margin-l-15"></svg-icon>
                    <span class="margin-l-10">{{ $t('component.UserAvatarMenu_button_org_center') }}</span>
                </div>
                <div class="item divider" @click="handlerCommand('logout')">
                    <svg-icon name="ib-exit" class-name="fonts-18 margin-l-15"></svg-icon>
                    <span class="margin-l-10">{{ $t('component.UserAvatarMenu_button_logout') }}</span>
                </div>
            </div>
        </el-dropdown-menu>
    </el-dropdown>
</template>
<script>
import moment from 'moment';
import $ from 'jquery';
import { mapState, mapGetters } from 'vuex';
import VipIcon from "@/components/icon/VipIcon";
import { userApi, orgApi } from "@/utils/api";
import tools from '@/utils/tools';
export default {
    components: { VipIcon, },
    data() {
        return {
            moment,
        }
    },
    computed: {
        ...mapState({
			userInfo: state => state.user.userData,
            myOrgList: state => state.org.myOrgList,
            activeOrg: state => state.org.activeOrg,
            // noticeList: state => state.common.noticeList,
		}),
        ...mapGetters({
            isLogin:'user/loginStatus',
        }),
    },
    methods: {
        handlerCommand(type){
            let self = this;
            switch (type){
                case "goVip":
                    if (this.$checkPermission('account:VIPRouter')){
                        this.$router.push('/account/vip');
                    }
                    break;
                case "goUser":
                    this.$router.push('/account/index');
                    break;
                case "goOrg":
                    this.$router.push('/account/orgInfo');
                    break;
                case "logout":
                    let url= userApi.logout;
                    this.$ajax.delete(url,{}).then(res=>{
                        if(res.status === 200){
                            localStorage.removeItem("userInfo");
                            setTimeout(()=>{
                                location.replace('/login');
                            },500);
                        }
                    });
                    break;
            }
            this.closeMenu();
        },
        async transferOrgEvent(org){
            if (org.isSelected) return;
            let url = orgApi.transferOrg;
            let postData = {
                organizationId: org.organizationId,
                organizationTypeId: org.organizationType,
            };
            const changeMessage = this.$message({
                type: 'success',
                message: this.$t('component.UserAvatarMenu_message_switching_org'),
                iconClass: 'el-icon-loading',
            });
            await tools.sleep(1000);
            this.$ajax.post(url, postData).then(res => {
                if(res.status === 200){
                    this.$nextTick(() => {
                        location.replace('/');
                    });
                }
            }).finally(() => {
                changeMessage.close();
            })
        },
        closeMenu(){
            $('.user-avatar-tigger-btn').trigger('click');
        }
    },
    mounted() {

    }
}
</script>
<style lang="scss" scoped>
.user-avatar-tigger-btn{
    display: flex;
    justify-content: center;
    cursor: pointer;
    img{
        width: 26px;
        height: 26px;
        border-radius: 50%;
    }
    .vip-icon{
        position: absolute;
        bottom:-1px;
        right: -2px;
    }
}
.user-avatar-dropdown{
    width: 270px;
    // min-height: 350px;
    border-radius: 8px;
    padding: 20px 15px 10px 15px;
    box-sizing: border-box;
    .vip-wapper{
        margin-top: 15px;
        background-color: #F2F2F2;
        border-radius: 8px;
        padding: 15px;
        cursor: pointer;
        &.free {
            background-color: #F2F2F2;
        }
        &.pro {
            background-color: #FDF4E1;
        }
        &.enterprise {
            background-color: #F7F3FB;
        }

    }
    .menu-list{
        margin-top: 10px;

        .item{
            display: flex;
            align-items: center;
            height: 40px;
            background-color: transparent;
            color: #666666;
            box-sizing: border-box;
            font-size: 12px;
            cursor: pointer;
            &:hover {
                border-radius: 8px;
                background-color: #F2F2F2;
                color: #000000;
            }
            &.divider{
                border-top: 1px solid #e6e6e6;
            }
            ::v-deep .el-popover__reference-wrapper{
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                // display: block;
            }
        }
    }
}
.transfer-org-list{
    .item {
        display: flex;
        height: 50px;
        align-items: center;
        // border-radius: 8px;
        cursor: pointer;
        padding: 0 20px;
        &:hover {
            background-color: #F2F2F2;
        }
        &:first-child {
            border-radius: 12px 12px 0 0;
        }
        &:last-child {
            border-radius: 0 0 12px 12px;
        }
    }
}
</style>
<style lang="scss">
/* .transfer-wrapper {
    
} */
.transfer-wrapper.el-popper[x-placement^="left"]{
    width: 200px;
    border-radius: 12px;
    margin-right: 18px;
    padding: 0;
}

</style>
