import { commonApi, projectApi } from '@/utils/api';
import tools from '@/utils/tools';
import ajax from '@/utils/ajax';
import qs from 'querystring';
import router from '@/router';


const state=()=>({
    breadcrumb:[],
    showBackBtn:false,
    acceptSize:0,
    engineConfig:{},
    showFullLoading:false,
    uploadToken: {},
    engineLabels: [],
    gptEngineStyleTypes: [],
    gptEngineTranslateTypes: [],
    polishTextTypes: [],
    polishStyleOptions: [],
    noticeList: [],
    messageList: [],
    messageCount: 0,
    refreshHeader: '',
    LANGUAGE_DICT: {},
    
})

const getters={

}

const actions ={
    initBreadcrumbAction({commit},data){
        commit('INIT_BREADCRUMB',data);
    },
    initBackBtnAction({commit},data){
        commit('INIT_BACK_BTN',data);
    },
    updateAcceptSizeAction({commit},data){
        commit('UPDATE_ACCEPT_SIZE',data);
    },
    initEngineConfigAction({commit},data){
        commit('INIT_ENGINE_CONFIG',data);
    },
    showFullLoading({commit}){
        commit('SET_FULL_LOADING',true);
    },
    hideFullLoading({commit}){
        commit('SET_FULL_LOADING',false);
    },
    initUploadTokenAction({commit}, param){
        return new Promise((resolve, reject) => {
            let type = router.app._route.meta.type || '';
            if(param) {
                type = param;
            }
            let url = projectApi.queryUploadToken;
            if(type){
                url = `${url}?type=${type}`;
            }
            ajax.get(url).then(res => {
                if(res.status === 200){
                    commit('INIT_UPLOAD_TOKEN', res.data);
                    resolve(res.data);
                }
            }).catch(err => {
                reject(err);
            })
        });
    },
    initDictDataAction({commit}, data){
        commit('INIT_DICT_DATA', data);
    },

    getNoticeList({commit}, data){
        commit('GET_NOTICE_LIST', data);
    },

    getMessageCount({commit}, data){
        commit('GET_MESSAGE_COUNT', data);
    },
    getMessageList({commit}, data){
        commit('GET_MESSAGE_LIST', data)
    },
    updateRefreshHeader({commit}) {
        let str = tools.getRandomId(8);
        commit('UPDATE_REFRESH_HEADER', str);
    },
    initLanguageDictAction({commit}) {
        return new Promise((resolve, reject) => {
            ajax.get(`${commonApi.queryLanguageDict}?keyword=`).then(res => {
                if (res.status === 200) {
                    commit('INIT_LANGUAGE_DICT', res.data)
                    resolve(res.data);
                }
            }).catch(err => {
                reject(err);
            })
        });
    },
}

const mutations = {
    INIT_BREADCRUMB(state,payload){      
        state.breadcrumb=payload.breadcrumb;
    },
    INIT_BACK_BTN(state,payload){
        state.showBackBtn=payload.show;
    },
    UPDATE_ACCEPT_SIZE(state,payload){
        ajax.get(projectApi.queryPassOrderSize).then(res=>{
            if(res.result_code === 'success'){
                state.acceptSize=res.data;
            }
        });
    },
    INIT_ENGINE_CONFIG(state,payload){
        ajax.get(projectApi.queryAllEnginesList).then(res => {
            if(res.status === 200){
                let rst = {};
                res.data.map(item => {
                    rst[item.key] = item;
                });
                state.engineConfig = rst;
            }
        });
    },
    SET_FULL_LOADING(state,payload){
        state.showFullLoading = payload;
    },
    INIT_UPLOAD_TOKEN(state, payload){
        state.uploadToken = payload;
    },  
    INIT_DICT_DATA(state, payload){
        let url = commonApi.queryDictData;
        if(payload.type){
            url = `${url}?type=${payload.type}`;
            ajax.get(url).then(res => {
                if(res.status === 200){
                    switch (payload.type){
                        case 'mt-domains-tags':
                            state.engineLabels = res.data;
                            break;
                        case 'mt-style-type': 
                            state.gptEngineStyleTypes = res.data;
                            break;
                        case 'mt-translate-type':
                            state.gptEngineTranslateTypes = res.data;
                            break;
                        case 'tk-text-type':
                            state.polishTextTypes = res.data;
                            break;
                        case 'tk-polishing-style':
                            state.polishStyleOptions = res.data;
                            break;
                    }
                }
            });
        }
    },
    GET_NOTICE_LIST(state, payload){
        ajax.get(commonApi.queryNavigationList).then(res => {
            if(res.status === 200){
                state.noticeList = res.data;
            }
        })
    },
    GET_MESSAGE_COUNT(state, payload){
        ajax.get(commonApi.queyUnReadMessageCount).then(res => {
            if(res.status === 200){
                state.messageCount = res.data;
            }
        });
    },
    GET_MESSAGE_LIST(state, payload){
        ajax.post(commonApi.queryMessageList, payload).then(res => {
            if(res.status === 200){
                state.messageList = res.data.pager.records;
            }
        });
    },
    UPDATE_REFRESH_HEADER(state, payload) {
        state.refreshHeader = payload;
    },
    INIT_LANGUAGE_DICT(state, payload) {
        state.LANGUAGE_DICT = payload;
    },
}

export default{
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
