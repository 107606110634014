<template>
    <el-dropdown trigger="click" placement="bottom-start" @visible-change="handleVisibleChange">
      <div class="message-trigger-btn badge-style">
        <el-badge :value="size" :max="99" :hidden="size === 0">
            <svg-icon name="ib-letter"></svg-icon>
        </el-badge>
      </div>
      <el-dropdown-menu class="message-dropdown" slot="dropdown">
        <div class="message-wrapper" v-loading="loading">
            <div class="message-title">
                <span>{{ $t('component.MessageCenter_title') }}</span>
                <span class="read-all" @click="setReadAllEvent">{{ $t('component.MessageCenter_button_read_all') }}</span>
            </div>
            <div class="message-tabs">
                <el-tabs v-model="search.readStatus" @tab-click="tabChangeEvent">
                    <el-tab-pane :label="$t('component.MessageCenter_tab_label_all')" name="all"></el-tab-pane>
                    <el-tab-pane :label="$t('component.MessageCenter_tab_label_unread')" name="unread"></el-tab-pane>
                    <el-tab-pane :label="$t('component.MessageCenter_tab_label_read')" name="read"></el-tab-pane>
                </el-tabs>
            </div>
            <div class="message-main">
                <template v-if="messageList.length > 0">
                    <template v-for="item in messageList">
                        <div class="item" :key="item.id">
                            <div class="btn-group">
                                <div class="close-btn" @click="setReadEvent(item)" v-if="item.readStatus === 'unread'">
                                    <svg-icon name="ib-check-circle"></svg-icon>
                                </div>
                                <div class="close-btn" @click="closeEvent(item)">
                                    <svg-icon name="ib-close"></svg-icon>
                                </div>
                            </div>
                            <div class="flex flex-column margin-r-35">
                                <div class="type-name w-full" :class="{'unread': item.readStatus === 'unread'}"><div class="w-full text-omission">{{item.templateName}}</div></div>
                                <div class="time">{{ moment(item.createTime).format($t('component.MessageCenter_message_createTime_format')) }}</div>
                            </div>
                            <div class="content" v-html="item.templateContent"></div>
                            <div class="actions" v-if="item.action.length || item.actionResult">
                                <template v-if="item.action.length > 0">
                                    <template v-for="a in item.action">
                                        <a v-if="a === 1" href="javascript:;" @click="actionEvent(item, a)" :key="`action-${a}`">{{ $t('component.MessageCenter_button_join') }}</a>
                                        <!-- <a v-else-if="a === 2" href="javascript:;" @click="actionEvent(item, a)" :key="`action-${a}`">加入</a>-->
                                        <a v-else-if="a === 3" href="javascript:;" @click="actionEvent(item, a)" :key="`action-${a}`">{{ $t('component.MessageCenter_button_go_review') }}</a> 
                                    </template>
                                </template>
                                <template v-if="item.actionResult">
                                    <div class="status success">{{item.actionResult.result}}</div>
                                </template>
                            </div>
                        </div>
                    </template>
                </template>
                <template v-else>
                    <el-empty :description="$t('component.MessageCenter_no_message_tips')">
                        <template slot="image">
                            <svg-icon name="ib-empty" style="font-size:50px; color: #888;"></svg-icon>
                        </template>
                    </el-empty> 
                </template>
            </div>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
</template>
<script>
import { mapState } from 'vuex';
import moment from 'moment';
import { debounce } from 'lodash';
import { commonApi } from '@/utils/api';
export default {
    name: 'MessageCenter',
    data() {
        return {
            // size: 10,
            loading: false,
            timer: null,
            search: {
                pageNumber: 1, 
                pageSize: 100,
                readStatus: 'unread',
            },
            
            alertTimer: null,
        }
    },
    computed: {
        ...mapState({
            messageList: state => state.common.messageList,
            size: state => state.common.messageCount,
        })
    },
    methods: {
        moment,
        handleVisibleChange(val){
            if(val){
                this.search.readStatus = 'all';
                this.$store.dispatch("common/getMessageList", {...this.search, readStatus: this.search.readStatus === 'all' ? '' : this.search.readStatus});
            }
        },
        tabChangeEvent(){
            this.$nextTick(() => {
                this.$store.dispatch("common/getMessageList", {...this.search, readStatus: this.search.readStatus === 'all' ? '' : this.search.readStatus});
            })
        },
        pollingMessageCount(){
            this.$store.dispatch("common/getMessageCount")
             this.timer = setInterval(() =>{
                this.$store.dispatch("common/getMessageCount");
            }, 1000 * 60 * 10);
        },
        closeEvent: debounce(function (item){
            this.$ajax.post(commonApi.deleteMessage,{id: item.id}).then(res => {
                if(res.status === 200){
                    this.$store.dispatch("common/getMessageCount");
                    this.$store.dispatch("common/getMessageList", {...this.search, readStatus: this.search.readStatus === 'all' ? '' : this.search.readStatus});
                }
            })
        }),
        actionEvent: debounce(function (item, action){
            switch(action){
                case 1:
                    this.$ajax.post(commonApi.triggerMessageAction, {id: item.id, action: action}).then(res => {
                        if(res.status === 200){
                            this.$store.dispatch("common/getMessageCount");
                            this.$store.dispatch("common/getMessageList", {...this.search, readStatus: this.search.readStatus === 'all' ? '' : this.search.readStatus});
                        }
                    })
                    break;
                case 3:
                    this.$router.push('/team/index');
                    this.setReadEvent(item);
                    break;
            }
           
        }),
        setReadAllEvent: debounce(function (){
            this.$ajax.get(`${commonApi.setMessageReadAll}`).then(res => {
                if(res.status === 200) {
                    this.$store.dispatch("common/getMessageCount");
                    this.$store.dispatch("common/getMessageList", {...this.search, readStatus: this.search.readStatus === 'all' ? '' : this.search.readStatus});
                }
            });
        }),
        setReadEvent: debounce(function (item){
            this.$ajax.get(`${commonApi.setMessageRead}?id=${item.id}`).then(res => {
                if(res.status === 200) {
                    this.$store.dispatch("common/getMessageCount");
                    this.$store.dispatch("common/getMessageList", {...this.search, readStatus: this.search.readStatus === 'all' ? '' : this.search.readStatus});
                }
            });
        }),

        // 消息提醒功能
        initAlertMessageList() {
            let self = this;
            let url = commonApi.queryAlertMessageList;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    let messageGroup = res.data;
                    if(messageGroup && Object.keys(messageGroup).length) {
                        // console.log('[INFO] 消息通知 popup=%s', Object.keys(messageGroup).length);
                        let index = 0;
                        for(let key in messageGroup) {
                            let title = key;
                            let content = ``;
                            messageGroup[key].map(item => {
                                content = `${content}<p class="message-item">${item.templateContent}</p>`;
                            });
                            setTimeout(() => {
                                self.$notify({
                                    title: title,
                                    message: content,
                                    dangerouslyUseHTMLString: true,
                                    customClass: 'message-notify',
                                    duration: 1000 * 5,
                                })
                            }, index);
                            index ++;
                        }
                    }
                }
            });
        },
        pollingMessageAlert() {
            let self = this;
            self.initAlertMessageList();
            this.alertTimer = setInterval(() => {
                self.initAlertMessageList();
            }, 1000 * 10 * 6);
        },
    },
    mounted(){
        let isLogin = localStorage.getItem('userInfo') !==null && this.$cookies.get('token')!==null;
        if(isLogin){
            this.pollingMessageCount();
            // this.pollingMessageAlert();
        }
    },
    beforeDestroy(){
        if(this.timer){
            clearInterval(this.timer);
            this.timer = null;
        }
        if(this.alertTimer) {
            clearInterval(this.alertTimer);
            this.alertTimer = null;
        }
    }
}
</script>
<style lang="scss" scoped>
.message-trigger-btn{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover{
        background-color: #EDEDED;
    }
}
.message-dropdown{
    padding: 0;
}
.message-wrapper{
    width: 268px;
    // min-height: 240px;
    max-height: 680px;
    display: flex;
    flex-direction: column;
    .message-title{
        padding: 15px;
        text-align: center;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        border-bottom: 1px solid #f1f1f1;
        .read-all{
            color: #2979FF;
            cursor: pointer;
        }
    }
    .message-main{
        flex: 1;
        overflow-y: auto;
        padding: 10px 15px;
        .item{
            padding: 15px;
            margin-bottom: 10px;
            background: #F8F8F8;
            border-radius: 4px;
            position: relative;
            .btn-group{
                display: flex;
                position: absolute;
                right: 5px;
                top: 0;
                .close-btn{
                    padding: 10px 5px;
                    font-size: 12px;
                    color: #b3b3b3;
                    cursor: pointer;
                    &:hover {
                        color: #000000;
                    }
                }
            }
            .type-name {
                font-weight: 600;
                font-size: 12px;
                &.unread {
                    position: relative;
                    &::before {
                        content: ' ';
                        width: 6px;
                        height: 6px;
                        // padding-left: 5px;
                        display: inline-block;
                        background-color: #EB7C7C;
                        border-radius: 20px;
                        position: absolute;
                        left: -10px;
                        top: 3px;
                    }
                }
            }
            .time {
                font-size: 12px;
                margin-top: 5px;
                color: #999999;
            }
            .content {
                font-size: 13px;
                line-height: 18px;
                color: #666666;
                margin-top: 10px;
                ::v-deep b{
                    color: #000000;
                    margin: 0 4px;
                }
            }
            .actions {
                margin-top: 10px;
                display: flex;
                align-items: center;
                > a {
                    font-size: 14px;
                    margin-right: 10px;
                }
                .status {
                    margin-right: 10px;
                    height: 20px;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    color: #333333;
                    font-size: 12px;
                    font-weight: 500;
                    padding: 0 8px;
                    display: flex;
                    align-items: center;
                    &.success {
                        background-color: #DDF3EB;
                        color: #00B670;
                    }
                }
            }
        }
    }
}
.message-tabs{
    // height: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    border-bottom: 1px solid #f1f1f1;
    ::v-deep .el-tabs{
        height: 100%;
        display: flex;
        flex-direction: column;
        .el-tabs__header{
            margin-bottom: 0;
            
        }
        .el-tabs__content{
            flex: 1;
            padding: 0px;
            overflow-y: auto;
        }
    }
    ::v-deep .el-tabs__nav-wrap::after {
        height: 0px;
        background-color: transparent;
    }
    ::v-deep .el-tabs__item {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        color: #000000;
        &.is-active {
            font-weight: 600;
        }
    }
    ::v-deep .el-tabs--top{ 
        .el-tabs__item.is-top:nth-child(2) {
            // padding-left: 15px;
        }
    }
}

</style>
<style lang="scss">
.message-notify {
    padding: 20px 15px 18px 15px !important;
    border-radius: 10px !important;
    .el-notification__group {
        margin: 0px;
    }
    .el-notification__title {
        font-size: 13px;
    }
    .el-notification__content {
        margin-top: 15px;
    }
    .message-item {
        background-color: #F8F8F8;
        border-radius: 4px;
        padding: 10px;
        margin-bottom: 10px;
        font-size: 13px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
}
</style>
