<template>
    <el-dropdown @command="handleLanguageCommand">
        <span class="flex flex-align-center cursor-pointer color-222222">
            <svg-icon name="ib-locale" className="fonts-14"></svg-icon>
            <span class="margin-l-5 fonts-12">{{ lang.name }}</span>
        </span>
        <el-dropdown-menu slot="dropdown">
            <template v-for="(item, index) in langList">
                <el-dropdown-item class="fonts-14" :command="item.key" :key="index" :disabled="item.key === lang.key">{{ item.name }}</el-dropdown-item>
            </template>
        </el-dropdown-menu>
    </el-dropdown>
</template>
<script>
import {i18n, setLocale} from '@/assets/i18n'
export default {
    data() {
        return {
            lang: {key: 'zh-CN', name: '简体中文'},
            langList: [
                {key: 'zh-CN', name: '简体中文'},
                {key: 'en-US', name: 'English'},
            ],
        }
    },
    created() {
        let locale = i18n.locale;
        this.lang = this.langList.find(item => item.key === locale);
    },
    methods: {
        handleLanguageCommand(command) {
            this.lang = this.langList.find(item => item.key === command);
            setLocale(command);
        }
    }
}
</script>
