<template>
    <el-dialog :title="charDialog.title" :visible.sync="charDialog.show" destroy-on-close @open="openCharDialogEvent" @close="closeCharDialogEvent" width="872px" top="20px">
        <div class="pay-pro-success" v-if="status === 'paid'">
            <i class="el-icon-success"></i>
            <div class="fonts-20 margin-t-10">购买成功</div>
            <div class="fonts-14 margin-t-10">感谢您的信任，欢迎加入用户交流群~ </div>
            <img src="../../static/images/tencent-group-qrcode.png" alt="公众号二维码"/>
            <div class="fonts-14 margin-t-10">扫码加入官方交流群</div>
        </div>
        <div class="pay-char-dialog" v-loading="loading" v-else>
            <div class="pay-type-content">
                <div :class="['item', charDialog.type === 'c10'?'active':'']" @click="changePayTypeEvent('c10')">
                    <div class="text-weight-600 fonts-18">10万字符</div>
                    <div class="color-919191 fonts-12 margin-t-10">2.5元/万字符</div>
                    <div class="text-weight-700 fonts-30 margin-t-10">￥25<!-- <span class="line-through margin-l-10 fonts-16 color-919191">￥19</span> --></div>
                    <!-- <div class="tag">限时</div> -->
                </div>
                <div :class="['item', charDialog.type === 'c50'?'active':'']" @click="changePayTypeEvent('c50')">
                    <div class="text-weight-600 fonts-18">50万字符</div>
                    <div class="color-919191 fonts-12 margin-t-10">1.98元/万字符</div>
                    <div class="text-weight-700 fonts-30 margin-t-10">￥99<!-- <span class="line-through margin-l-10 fonts-16 color-919191">￥59</span> --></div>
                </div>
                <div :class="['item', charDialog.type === 'c100'?'active':'']" @click="changePayTypeEvent('c100')">
                    <div class="text-weight-600 fonts-18">100万字符</div>
                    <div class="color-919191 fonts-12 margin-t-10">1.39元/万字符</div>
                    <div class="text-weight-700 fonts-30 margin-t-10">￥139<!-- <span class="line-through margin-l-10 fonts-16 color-919191">￥99</span> --></div>
                </div>
                <div :class="['item', charDialog.type === 'c1000'?'active':'']" @click="changePayTypeEvent('c1000')">
                    <div class="text-weight-600 fonts-18">1000万字符</div>
                    <div class="color-919191 fonts-12 margin-t-10">1.251元/万字符</div>
                    <div class="text-weight-700 fonts-30 margin-t-10">￥1251<!-- <span class="line-through margin-l-10 fonts-16 color-919191">￥99</span> --></div>
                    <!-- <div class="tag erp">企业版</div> -->
                </div>
            </div>
            <div class="pay-detail-content">
                <template v-if="order">
                    <div class="qrcode">
                        <img class="width-full" :src="order.codeImgUrl" alt="qrcode"/>
                    </div>
                    <div class="fonts-12" style="padding-left:30px;">
                        <div>订单编号<span class="margin-l-10">{{order.orderNo}}</span></div>
                        <div class="margin-t-15">付款金额<span class="margin-l-10 fonts-24">¥ {{order.amount}}</span></div>
                        <div class="margin-t-15 flex flex-align-center">扫码支付<svg-icon name="ic-wechat-pay" className="margin-l-10" style="width: 22px;height: 22px;"></svg-icon><span class="margin-l-5">微信</span></div>
                        <div class="margin-t-15"><router-link to="/account/vip" target="_blank">了解PRO版本更多权益</router-link></div>
                    </div>
                </template>
            </div>
            <div class="fonts-12 margin-t-25 text-center">订阅LanguageX服务，即表示同意我们的 <a href="http://static.languagex.com/page/serviceTerms.html" target="_blank">《服务协议》</a> 和 <a href="http://static.languagex.com/page/privacyPolicy.html" target="_blank">《隐私政策》</a></div>
        </div>
    </el-dialog>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { userApi, payApi } from '@/utils/api';
export default {
    name: 'PayCharDialog',
    data(){
        return{
            // active: 10, // 50 100
            order: null,
            loading: false,
            orderStatusTimer: null,
            status: '',
        }
    },

    computed: {
        ...mapState({
            charDialog: state=>state.pay.charDialog,
        })
    },

    methods: {
        closeCharDialogEvent(){
            // this.active = 10;
            this.status = '';
            this.$store.dispatch('pay/setCharDialog',{show: false, type: 'c10'});
            clearInterval(this.orderStatusTimer);
            this.orderStatusTimer = null;
            this.$router.replace('/account/vip?view=char');
            setTimeout(()=>{
                location.reload(true);
            }, 800);
        },
        openCharDialogEvent(){
            this.initOrder();
        },
        initOrder(){
            let self = this;
            let url = `${payApi.createOrder}`;
            this.loading = true;
            this.$ajax.post(url,{
                method: 'pack',
                packCode: this.charDialog.type,
                channel: 'wechat',
            }).then(res=>{
                if(res.status === 200){
                    this.order = res.data;
                    if(this.orderStatusTimer){
                        clearInterval(this.orderStatusTimer);
                        this.orderStatusTimer = null;
                    }
                    this.orderStatusTimer = setInterval(() =>{
                        self.pollingOrderStatus();
                    },1000);
                }
            }).finally(()=>{
                this.loading = false;
            })
        },
        changePayTypeEvent(type){
            this.$store.dispatch('pay/setCharDialog',{type});
            this.initOrder();
        },
        pollingOrderStatus(){
            let url = `${payApi.queryOrder}?orderNo=${this.order.orderNo}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    let data = res.data;
                    if(data.orderStatus === 'paid'){
                        this.status = data.orderStatus;
                        clearInterval(this.orderStatusTimer);
                        this.orderStatusTimer = null;
                    }else if(data.orderStatus === 'failed'){
                        this.$message.success("支付失败!");
                        this.initOrder();
                    }
                }
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.pay-char-dialog{
    margin: 10px 0;
    min-height: 326px;
    .pay-type-content{
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        .item{
            position: relative;
            padding: 20px;
            width: 190px;
            margin-right: 20px;
            border: 2px solid transparent;
            box-sizing: border-box;
            border-radius: 12px;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
            cursor: pointer;
            user-select: none;
            &:last-child{
                margin-right: 0;
            }
            &.active{
                border-color: #00D959;
            }
            .tag{
                border-radius: 0 12px 0 12px;
                background-color: #F56C6C;
                width: 48px;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 500;
                font-size: 12px;
                color: #FFFFFF;
                position: absolute;
                top: -2px;
                right: -2px;
                &.erp {
                    background-color: #AA84DA;
                }
            }
        }
    }
    .pay-detail-content{
        width: 407px;
        height: 170px;
        padding: 0 26px;
        margin: 0 auto;
        background-color: #FFFFFF;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        .qrcode{
            width: 140px;
            height: 140px;
        }
    }
}
.pay-pro-success{
    margin-top: 20px;
    margin-bottom: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    i {
        font-size: 44px;
        color: #00B670;
    }
    img {
        margin-top: 40px;
        width: 140px;
    }

}
</style>
