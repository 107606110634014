<template>
    <div class="faq-component">
        <el-button type="text" class="faq-btn" @click="setShow"><svg-icon name="ic-feedback"></svg-icon></el-button>
        <div class="faq-wrapper" v-show="show"> <!-- robot.languagex.cn:3000 -->
            <iframe ref="faqIframe" :src="iframeSrc" 
                width="408px"
                height="594px"
                frameborder="0">
            </iframe>
            <el-button type="text" class="close-btn" @click="show = !show"><svg-icon name="ib-close"></svg-icon></el-button>
            <div class="faq-cover"></div>
        </div>
        
    </div>
</template>
<script>
export default {
    name: "Faq",
    data() {
        return {
            show: false,
            iframeSrc: '',
        }
    },
    
    methods: {
        setShow(){
            // this.show = !this.show;
            window.open('http://robot.languagex.cn:3000', '_blank');
        }
    },

    watch: {
        "show" (newVal, oldVal) {
            if(newVal){
                this.iframeSrc = 'http://robot.languagex.cn:3000';
            }else {
                this.iframeSrc = '';
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.faq-component {
    z-index: 900;
    position: fixed;
    bottom: 50px;
    right: 30px;
    .faq-btn {
        width: 44px;
        height: 44px;
        background-color: #ffffff;
        border-radius: 50px;
        font-size: 26px;
        box-shadow: 0 4px 10px 0 #D8F1E7;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .faq-wrapper {
        position: fixed;
        bottom: 120px;
        right: 30px;
        background-color: #FFFFFF;
        border-radius: 8px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        .close-btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 50px;
            height: 50px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
        }
        iframe {
            border-radius: 8px;
        }
    }
    .faq-cover {
        width: 100%;
        height: 30px;
        position: absolute;
        left: 0;
        bottom: 0;
    }
}
</style>
