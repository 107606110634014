import axios from 'axios';
import {Message as $message} from "element-ui";
import { MessageBox } from 'element-ui';
import cookie from "vue-cookies";
import {i18n} from '@/assets/i18n/index';
import router from '../router';

let unLoginMessage = null;
let alertMessage555 = null;
let alertMessage556 = null;
let alertMessage222 = null;

axios.defaults.timeout=5*60*1000;
axios.defaults.baseURL= location.origin; 

/** 设置reqest拦截器*/
axios.interceptors.request.use(
    config=>{
        // 设置参数格式
        if(!config.headers['Content-Type']){
            config.headers = {
                ...config.headers,
                'Content-Type':'application/json',
                'Client-Language': i18n.locale,
                
            };
        }
        // 如果调用API需要鉴权，可以在这里设置
        let token=cookie.get("token");
        // 添加token到headers
        if(token){
            config.headers={
                ...config.headers,
            };
        }
        return config;
    },
    error=>{
        return Promise.reject(error);
    }
);
/** 设置response拦截器 假设后端结构返回的数据格式{"status":200,"data":null,"message":""}*/
axios.interceptors.response.use(
    response=>{
        const {status, message}=response.data;
        if ([200, 205].includes(status) ){
            return response.data;
        }else if([222].includes(status)){
            let data = response.data;
            let message = data.message;
            // console.log('+++++++222::::', data);
            if(!alertMessage222) {
                alertMessage222 = MessageBox({
                    title: i18n.t('common.notice_title'),
                    dangerouslyUseHTMLString: true,
                    message: `<div style="padding: 0 10px;">${message.content}</div>`,
                    showClose: false,
                    iconClass: null,
                    confirmButtonText: i18n.t('common.notice_button_3'),
                    roundButton: true,
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                }).then(async ()=>{
                    await axios.get(`/api/gateway/platform-setup/popup/close?messageId=${message.id}`);
                })
            }
            data.status = 200;
            return data;
        }else if([555].includes(status)){
            if (!alertMessage555) {
                alertMessage555 = MessageBox({
                    title: i18n.t('common.notice_title'),
                    message: message || i18n.t('common.notice_message_1'),
                    type: 'error',
                    confirmButtonText: i18n.t('common.notice_button_1'),
                    roundButton: true,
                }).then(()=>{
                    location.reload(true);
                    alertMessage555 = null;
                }).catch(()=>{
                    location.reload(true);
                    alertMessage555 = null;
                });
            }
                
        }else if([556].includes(status)){
            if (!alertMessage556) {
                alertMessage556 = MessageBox({
                    title: i18n.t('common.notice_title'),
                    message: message || i18n.t('common.notice_message_2'),
                    type: 'error',
                    confirmButtonText: i18n.t('common.notice_button_2'),
                    roundButton: true,
                }).then(()=>{
                    location.replace('/task/index');
                    alertMessage556 = null;
                }).catch(()=>{
                    location.replace('/task/index');
                    alertMessage556 = null;
                });
            }
                
        }else {
            if(message){
                $message({
                    showClose: true,
                    message: message,
                    type: 'error'
                });
            }
        }
        // return response.data;
    },
    error=>{
        if (error && error.response) {
            switch (error.response.status){
                case 400:
                    error.message = i18n.t('common.response_400');
                    break;
                case 401:
                    error.message = i18n.t('common.response_401');
                    break;
                case 403:
                    error.message = i18n.t('common.response_403');
                    break;
                case 404:
                    error.message = i18n.t('common.response_404');
                    break;
                case 405:
                    error.message = i18n.t('common.response_405');
                    break;
                case 408:
                    error.message = i18n.t('common.response_408');
                    break;
                case 429:
                    error.message = i18n.t('common.response_429');
                    break;
                case 500:
                    error.message = i18n.t('common.response_500');
                    break;
                case 501:
                    error.message = i18n.t('common.response_501');
                    break;
                case 502:
                    error.message = i18n.t('common.response_502');
                    break;
                case 503:
                    error.message = i18n.t('common.response_503');
                    break;
                case 504:
                    error.message = i18n.t('common.response_504');
                    break;
                case 505:
                    error.message = i18n.t('common.response_505');
                    break;
                case 59574:
                    error.message = i18n.t('common.response_59574');
                    break;
                default:
                    error.message = i18n.t('common.response_default', {code: error.response.status});
            }

            // console.error('[ERROR] ajax response:',error.response);

            let _message =  error.response.data && error.response.data.message ? error.response.data.message:''
            
            // 需要登录
            if(error.response.status === 401){ 
                if(unLoginMessage === null){
                    unLoginMessage = $message({
                        showClose: true,
                        message: _message?_message:error.message,
                        type: 'error',
                        onClose: ()=>{
                            unLoginMessage = null;
                        }
                    });
                }
                cookie.remove("token");
                localStorage.removeItem("userInfo");
                localStorage.setItem('redirect',location.href);
                setTimeout(()=>{
                    router.replace('/login');
                },3000);
                
            }else {
                $message({
                    showClose: true,
                    message: _message?_message:error.message,
                    type: 'error'
                });
            }
            return Promise.reject(error);
        }
        let _error = JSON.parse(JSON.stringify(error));
        // console.error('[ERROR] ajax:', _error);
        if(_error.message === 'Network Error'){
            $message({
                showClose: true,
                message: i18n.t('common.network_error'),
                type: 'error'
            });
        }
        return Promise.reject(error);
    }
);
let format = (url,params)=>{
    let result = url;
    if(url){
        for(let key in params){
            result = result.replace(new RegExp("\\{" + key + "\\}", "g"), params[key]);
        }
        return result;
    }else{
        throw i18n.t('common.exception_message_1');
    }
}
export default {
    axios,
    get:(url) => axios.get(url),
    post:(url,params) => axios.post(url,params),
    put:(url,params) => axios.put(url,params),
    path:(url,params) => axios.path(url,params),
    delete:(url) => axios.delete(url),
    /**
     * 文件上传需要创建FormData,把文件追加到ForData中
     * let formData=new FormData();
     * formData.append('file',file);
     */
    fileUpload:(url, params, config) => {
        let formData = new FormData();
        let keys = Object.keys(params);
        keys.map(key => {
            formData.append(key, params[key]);
        });
        return axios.post(url, formData, {
            ...config,
            headers: {
                'Content-Type': 'multipart/form-data',
                ...(config.headers || {})
            },
        });
    }, 
    all:(array) => axios.all(array),
    format,


};
