import { orgApi } from '@/utils/api';
import ajax from '@/utils/ajax';

const state=()=>({
    myOrgList: [],
    activeOrg: {},
})

const getters = {

}

const actions = {
    queryMyOrgList({commit}, id){
        commit('QUERY_MY_ORG_LIST', id);
    },
}

const mutations = {
    QUERY_MY_ORG_LIST(state, payload){
        let url = orgApi.queryMyOrgList;
        ajax.get(url).then(res => {
            if (res.status === 200){
                state.myOrgList = res.data.all;
                state.activeOrg = res.data.active;
            }
        })
    },
    
}

export default{
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
