import store from '../../store/index';

function checkPermission(permissions){
    let _permissions = permissions;
    if (!_permissions){
        return true;
    }
    if(Object.prototype.toString.call(permissions) !== '[object Array]'){
        if(permissions.indexOf(',') > 0){
            _permissions = permissions.split(',').filter(p => p.trim().length > 1);
        }else{
            _permissions = [permissions];
        }
    }
    const all = store.state.user.permissions.all;
    for (let permission of _permissions){
        if(all.indexOf(permission) > -1){
            return true;
        }
    }
    return false;
}

const permissionDirective = {
    inserted: function (el, binding) {
        const value = binding.value;
        // console.log('inserted=====', value, has)
        // console.log('inserted=====',el, binding)
        if(binding.arg === 'delay'){
            setTimeout(function(){
                if (!checkPermission(value)) {
                    el.remove();
                }
            }, 500)
        }else{
            if (!checkPermission(value)) {
                el.remove();
            }
        }
        
    }
};

const Permission = {
    install: function(Vue, options) {
        Vue.directive('permission', permissionDirective);

        Vue.prototype.$checkPermission = checkPermission;
    }
};

export {
    Permission,
    checkPermission,
}
