export default {
    common: {
        response_400: 'Bad Request',
        response_401: 'Your Session Has Expired. Please Login Again to Continue.',
        response_403: 'Access Denied, You May Not Have Permission',
        response_404: 'Resource Not Found',
        response_405: 'Request Method Not Allowed',
        response_408: 'Request Timed Out',
        response_429: 'Too Many Attempts. Try Again Later.',
        response_500: 'Server-Side Errors',
        response_501: 'Not Implemented',
        response_502: 'Network Error',
        response_503: 'Service Unavailable',
        response_504: 'Network Timeout',
        response_505: 'HTTP Version Not Supported',
        response_59574: 'Projects containing files that have not been pre-translated are not available for download.',
        response_default: 'Connection Error, CODE: {code}',
        network_error: 'Processing Failed. Please Check Your Network',
        notice_title: 'Notice',
        notice_message_1: 'Invalid Submission, Please Refresh Your Screen And Try Again.',
        notice_message_2: 'The Task Has Timed Out.',
        notice_button_1: 'Refresh Page',
        notice_button_2: 'Return To Task List',
        notice_button_3: 'Confirm',
        exception_message_1: 'URL Is Not Imported!',
        country_code_list: [
            {
                name: 'Albania',
                code: '355',
            },
            // {name: '阿根廷', code: '54'},
            // {name: '阿拉伯联合酋长国', code: '971'},
            // {name: '阿鲁巴', code: '297'},
            // {name: '爱尔兰', code: '353'},
            {
                name: 'Estonia',
                code: '372',
            },
            // {name: '安道尔', code: '376'},
            // {name: '安哥拉', code: '244'},
            // {name: '安圭拉', code: '1264'},
            // {name: '安提瓜和巴布达', code: '1268'},
            // {name: '奥地利', code: '43'},
            {
                name: 'Australia',
                code: '61',
            },
            // {name: '巴巴多斯', code: '1246'},
            // {name: '巴布亚新几内亚', code: '675'},
            // {name: '巴哈马', code: '1242'},
            // {name: '巴拉圭', code: '595'},
            // {name: '巴林', code: '973'},
            // {name: '巴拿马', code: '507'},
            // {name: '巴西', code: '55'},
            // {name: '百慕大群岛', code: '1441'},
            {
                name: 'Bulgaria',
                code: '359',
            },
            // {name: '比利时', code: '32'},
            // {name: '冰岛', code: '354'},
            // {name: '波多黎各', code: '1787'},
            {
                name: 'Poland',
                code: '48',
            },
            // {name: '波斯尼亚和黑塞哥维那', code: '387'},
            // {name: '伯利兹', code: '501'},
            // {name: '博茨瓦纳', code: '267'},
            // {name: '布基纳法索', code: '226'},
            // {name: '布隆迪', code: '257'},
            // {name: '朝鲜', code: '850'},
            // {name: '赤道几内亚', code: '240'},
            {
                name: 'Denmark',
                code: '45',
            },
            // {name: '东帝汶', code: '670'},
            // {name: '多哥', code: '228'},
            // {name: '多米尼加共和国', code: '1809'},
            // {name: '多米尼克', code: '1767'},
            // {name: '厄立特里亚', code: '291'},
            {
                name: 'France',
                code: '33',
            },
            // {name: '法罗群岛', code: '298'},
            // {name: '斐济', code: '679'},
            {
                name: 'Finland',
                code: '358',
            },
            // {name: '佛得角', code: '238'},
            // {name: '冈比亚', code: '220'},
            // {name: '刚果共和国', code: '242'},
            // {name: '刚果民主共和国（扎伊尔）', code: '243'},
            // {name: '哥伦比亚', code: '57'},
            // {name: '哥斯达黎加', code: '506'},
            // {name: '格林纳达', code: '1473'},
            // {name: '格陵兰岛', code: '299'},
            // {name: '古巴', code: '53'},
            // {name: '关岛', code: '1671'},
            // {name: '圭亚那', code: '592'},
            // {name: '哈萨克斯坦', code: '997'},
            // {name: '海地', code: '509'},
            {
                name: 'South Korea',
                code: '82',
            },
            {
                name: 'Netherlands',
                code: '31',
            },
            // {name: '黑山', code: '382'},
            // {name: '洪都拉斯', code: '504'},
            // {name: '基里巴斯', code: '686'},
            // {name: '吉布提', code: '253'},
            // {name: '几内亚', code: '224'},
            // {name: '几内亚比绍共和国', code: '245'},
            {
                name: 'Canada',
                code: '1',
            },
            // {name: '加蓬', code: '241'},
            {
                name: 'Czech',
                code: '420',
            },
            // {name: '津巴布韦', code: '263'},
            // {name: '喀麦隆', code: '237'},
            // {name: '卡塔尔', code: '974'},
            // {name: '开曼群岛', code: '1345'},
            // {name: '科摩罗', code: '269'},
            // {name: '科特迪瓦', code: '225'},
            {
                name: 'Croatia',
                code: '385',
            },
            // {name: '肯尼亚', code: '254'},
            // {name: '拉脱维亚', code: '371'},
            // {name: '莱索托', code: '266'},
            {
                name: 'Laos',
                code: '856',
            },
            {
                name: 'Lithuania',
                code: '370',
            },
            // {name: '利比里亚', code: '231'},
            // {name: '列支敦士登', code: '423'},
            // {name: '卢森堡', code: '352'},
            // {name: '卢旺达', code: '250'},
            {
                name: 'Romania',
                code: '40',
            },
            // {name: '马达加斯加', code: '261'},
            // {name: '马尔代夫', code: '960'},
            // {name: '马耳他', code: '356'},
            {
                name: 'Malaysia',
                code: '60',
            },
            // {name: '马里亚纳群岛', code: '1670'},
            {
                name: 'Macedonia',
                code: '389',
            },
            // {name: '马约特', code: '262'},
            // {name: '毛里求斯', code: '230'},
            {
                name: 'United States',
                code: '1',
            },
            // {name: '美属萨摩亚', code: '1684'},
            // {name: '美属维尔京群岛', code: '1340'},
            // {name: '蒙特塞拉特岛', code: '1664'},
            // {name: '秘鲁', code: '51'},
            // {name: '密克罗尼西亚', code: '691'},
            // {name: '摩尔多瓦', code: '373'},
            // {name: '摩洛哥', code: '212'},
            // {name: '摩纳哥', code: '377'},
            // {name: '莫桑比克', code: '258'},
            // {name: '墨西哥', code: '52'},
            // {name: '纳米比亚', code: '264'},
            // {name: '南非', code: '27'},
            // {name: '南苏丹', code: '211'},
            // {name: '尼加拉瓜', code: '505'},
            // {name: '尼日尔', code: '227'},
            // {name: '挪威', code: '47'},
            // {name: '诺福克岛', code: '6723'},
            // {name: '帕劳', code: '680'},
            {
                name: 'Portugal',
                code: '351',
            },
            {
                name: 'Japan',
                code: '81',
            },
            {
                name: 'Sweden',
                code: '46',
            },
            // {name: '瑞士', code: '41'},
            // {name: '萨尔瓦多', code: '503'},
            // {name: '萨摩亚', code: '685'},
            // {name: '塞尔维亚', code: '381'},
            {
                name: 'Sierra Leone',
                code: '232',
            },
            // {name: '塞浦路斯', code: '357'},
            // {name: '塞舌尔', code: '248'},
            // {name: '沙特阿拉伯', code: '966'},
            // {name: '圣彼埃尔和密克隆岛', code: '508'},
            // {name: '圣多美和普林西比', code: '239'},
            // {name: '圣基茨和尼维斯', code: '1869'},
            // {name: '圣卢西亚', code: '1758'},
            // {name: '圣马丁岛（荷兰部分）', code: '1721'},
            // {name: '圣马力诺', code: '378'},
            // {name: '圣文森特和格林纳丁斯', code: '1784'},
            {
                name: 'Slovakia',
                code: '421',
            },
            // {name: '斯洛文尼亚', code: '386'},
            // {name: '斯威士兰', code: '268'},
            // {name: '苏里南', code: '597'},
            // {name: '所罗门群岛', code: '677'},
            {
                name: 'Thailand',
                code: '66',
            },
            // {name: '坦桑尼亚', code: '255'},
            // {name: '汤加', code: '676'},
            // {name: '特克斯和凯科斯群岛', code: '1649'},
            // {name: '特立尼达和多巴哥', code: '1868'},
            {
                name: 'Turkey',
                code: '90',
            },
            // {name: '瓦努阿图', code: '678'},
            // {name: '危地马拉', code: '502'},
            // {name: '委内瑞拉', code: '58'},
            // {name: '文莱', code: '673'},
            // {name: '乌拉圭', code: '598'},
            {
                name: 'Spain',
                code: '34',
            },
            {
                name: 'Greece',
                code: '30',
            },
            {
                name: 'Singapore',
                code: '65',
            },
            // {name: '新喀里多尼亚', code: '687'},
            {
                name: 'Hungary',
                code: '36',
            },
            // {name: '牙买加', code: '1876'},
            {
                name: 'Italy',
                code: '39',
            },
            {
                name: 'India',
                code: '91',
            },
            {
                name: 'United Kingdom',
                code: '44',
            },
            // {name: '英属维尔京群岛', code: '1284'},
            {
                name: 'Vietnam',
                code: '84',
            },
            // {name: '赞比亚', code: '260'},
            // {name: '直布罗陀', code: '350'},
            // {name: '智利', code: '56'},
            // {name: '中非共和国', code: '236'},
            {
                name: 'Macau (China)',
                code: '853',
            },
            {
                name: 'Chinese Mainland',
                code: '86',
            },
            {
                name: 'Taiwan (China)',
                code: '886',
            },
            {
                name: 'Hong Kong (China)',
                code: '852',
            },
        ],
    },
    component: {
        LanguageSelector_autoLang_result: 'Detected {name}',
        LanguageSelector_autoLang_label: 'Auto Detect',
        LanguageSelector_button_exchang: 'Click to Switch',
        LanguageSelector_source_language_name: 'Chinese',
        LanguageSelector_source_language_short_name: 'Zh',
        LanguageSelector_target_language_name: 'English',
        LanguageSelector_target_language_short_name: 'En',
        LanguageSelector_search_input_placeholder: 'Search Languages',
        LanguageSelect_button_exchang: 'Click to Switch',
        PageHeader_vip_name: 'Plan',
        PageHeader_help_item_1: 'Help Documentation',
        PageHeader_help_item_2: 'User Feedback',
        PageHeader_help_item_3: 'User Community',
        PageHeader_help_item_4: 'Request for Invoice',
        PageHeader_version_title: 'Version Update',
        PageHeader_version_label_new: 'New',
        PageHeaderTabs_tab_memory: 'Translation Memory',
        PageHeaderTabs_tab_term: 'Glossary',
        PageHeaderTabs_tab_polish: 'AI Polish',
        PageHeaderTabs_tab_translationScore: 'AI Translation Rating',
        PageHeaderTabs_tab_align: 'Alignment',
        PageHeaderTabs_tab_termExtraction: 'Terminology Extraction',
        PageHeaderTabs_tab_qa: 'QA Check',
        PageHeaderTabs_tab_detector: 'Machine Translation Detection',
        PageHeaderTabs_tab_videoTranslate: 'AI Video Translation',
        PageHeaderTabs_tab_gptTranslate: 'Document Intelligent Agent',
        PageHeaderTabs_tab_ragCreate: 'Custom RAG MT Model',
        PageHeaderTabs_tab_engineCreate: 'Deep Training',
        PageHeaderTabs_tab_myTasks: 'My Tasks',
        PageHeaderTabs_tab_taskStatistics: 'Total Tasks',
        DownloadNotices_drag_tips: 'Tap to Move',
        DownloadNotices_status_name_1: 'Export is in progress, please be patient',
        DownloadNotices_status_name_2: 'Export Successful',
        DownloadNotices_status_name_3: 'Export Failed',
        DownloadNotices_button_download: 'Download',
        DownloadNotices_download_link_fail_message: 'Failed to get download address, please try again',
        DownloadNotices_download_delete_remote_file_success: 'Delete Remote Download File Successfully',
        DragDialog_title: 'Note',
        MessageCenter_title: 'Notification Center',
        MessageCenter_button_read_all: 'Read All',
        MessageCenter_tab_label_all: 'All',
        MessageCenter_tab_label_unread: 'Unread',
        MessageCenter_tab_label_read: 'Read',
        MessageCenter_message_createTime_format: 'MM-DD-YYYY HH:mm',
        MessageCenter_button_join: 'Join',
        MessageCenter_button_go_review: 'Go to Review',
        MessageCenter_no_message_tips: 'No messages yet',
        UserAvatarMenu_expireTime_format: 'YYYY-MM-DD',
        UserAvatarMenu_expireTime_label: 'Expire',
        UserAvatarMenu_expireTime_label_expired: 'Expired',
        UserAvatarMenu_upgrade_to_pro: 'Upgrade PRO',
        UserAvatarMenu_info_label_1: 'Members:',
        UserAvatarMenu_info_label_2: 'Total Character:',
        UserAvatarMenu_info_label_3: 'Token:',
        UserAvatarMenu_button_switch_org: 'Switch to Enterprise/Education Version',
        UserAvatarMenu_button_user_center: 'User Center',
        UserAvatarMenu_button_org_center: 'Organization Center',
        UserAvatarMenu_button_logout: 'Log Out',
        UserAvatarMenu_message_switching_org: 'Switching',
        LeftAside_slogan: 'New Generation of AI Translation Platform',
        LeftAside_menu_item_help: 'Help',
        LeftAside_menu_item_help_child_1: 'Help Documentation',
        LeftAside_menu_item_help_child_2: 'User Feedback',
        LeftAside_menu_item_help_child_3: 'User Community',
        LeftAside_menu_item_help_child_4: 'Request for Invoice',
        LeftAside_menu_item_engine: 'Engine',
        LeftAside_menu_item_project: 'Project',
        LeftAside_menu_item_task: 'Task',
        LeftAside_menu_item_asset: 'Asset',
        LeftAside_menu_item_tools: 'Toolkit',
        LeftAside_menu_item_agent: 'Agent',
        LeftAside_menu_item_team: 'Team',
        AlertDialog_title: 'Note',
        AlertDialog_button_confirm: 'Confirm',
        AlertDialog_button_cancel: 'Cancel',
        BindWechat_title: 'Bind WeChat',
        BindWechat_scan_qrcode_title: 'Scan the QR code on WeChat',
        BindWechat_button_refresh: 'Refresh',
        BindWechat_qrcode_overdue_tips: 'QR Code Expired',
        BindWechat_tips_1: 'Scan the code and follow the official account of LanguageX. ',
        BindWechat_tips_2: 'Our message will not be much, but it is important for you!',
        BindWechat_bind_success_message: 'Account Bound Successfully!',
        FullLoading_title: 'Searching...',
        JargonDialog_title: 'Terminology Customization (Project Glossary)',
        JargonDialog_tips:
            'This glossary is automatically created for the project. The terms you uploaded or mounted on a new project are valid and are saved in the Translation Memory.',
        JargonDialog_tooltip_content: 'Support xlsx/xls/tbx format',
        JargonDialog_button_upload_title: 'Upload Glossary',
        JargonDialog_button_download_template: 'Download Template',
        JargonDialog_input_search_placeholder: 'Search Term',
        JargonDialog_table_column_index_label: 'No.',
        JargonDialog_table_column_source_label: 'Original',
        JargonDialog_table_column_target_label: 'Translation',
        JargonDialog_table_column_remark_label: 'Note',
        JargonDialog_table_column_action_label: 'Setting',
        JargonDialog_input_source_placeholder: 'Enter the original term',
        JargonDialog_input_target_placeholder: 'Enter a translation of the term',
        JargonDialog_input_remark_placeholder: 'Add Note',
        JargonDialog_button_save: 'Save',
        JargonDialog_button_cancel: 'Cancel',
        JargonDialog_button_edit: 'Edit',
        JargonDialog_button_delete: 'Delete',
        JargonDialog_button_reTranslate: 'Retranslate',
        JargonDialog_button_delete_confirm_title: 'Are you sure you want to delete it？',
        JargonDialog_downloading_template_message: 'Downloading',
        JargonDialog_download_fail_message: 'Failed to get download address',
        JargonDialog_term_upload_verification_message: 'Please make sure the file has been uploaded successfully',
        JargonDialog_term_upload_success_message: 'Upload Successful',
        JargonDialog_term_source_verification_message: 'The original term cannot be empty',
        JargonDialog_term_target_verification_message: 'The translation of term cannot be empty',
        JargonDialog_save_success_message: 'Saved successfully',
        JargonDialog_delete_success_message: 'Deleted',
        NextCollapse_title: 'Title',
        NextDrawer_button_close: 'Close',
        NextDrawer_button_confirm: 'Confirm',
        PageTitle_input_title_placeholder: 'Enter the Title, Enter to Save',
        PageTitle_edit_title_tooltip_content: 'Click Edit, Enter to Save',
        PageTitle_save_title_verification_message: 'The name cannot be empty',
        PayErpDialog_title: 'Subscribe to Enterprise Version',
        PayErpDialog_success_message_1: 'Subscription Successful',
        PayErpDialog_success_message_2: 'Thank you for your trust and welcome to join the user group!',
        PayErpDialog_success_message_3: 'Scan the QR code to join the official user group',
        PayErpDialog_button_apply_invoice: 'Request for Invoice',
        PayErpDialog_form_startTime_label: 'Service Start Time',
        PayErpDialog_form_startTime_placeholder: 'Select Service Start Time',
        PayErpDialog_form_endTime_label: 'Service End Time',
        PayErpDialog_form_endTime_placeholder: 'Select Service End Time',
        PayErpDialog_form_orgName_label: 'Full name of enterprise',
        PayErpDialog_form_orgName_placeholder: 'Full name of your enterprise required',
        PayErpDialog_form_button_cofirm: 'Confirm and Generate Agreement',
        PayErpDialog_form_orgName_tips_1: 'If the full name of the company is displayed as starting with LXO, ',
        PayErpDialog_form_orgName_tips_2: 'please modify',
        PayErpDialog_form_orderNo_label: 'Order Number',
        PayErpDialog_form_amount_label: 'Payment Amount',
        PayErpDialog_form_scan_label: 'Scan to pay',
        PayErpDialog_form_scan_platform_wechat: 'WeChat',
        PayErpDialog_currency_symbol: '￥',
        PayErpDialog_form_org_benefits_tips: 'Learn more about Enterprise/Education Version',
        PayErpDialog_form_cofirm_tips_1: 'Click "Confirm and Generate Agreement" button',
        PayErpDialog_form_cofirm_tips_2:
            'Confirm your contract on the new page and return to this page to make payment',
        PayErpDialog_bottom_tips_1: 'By subscribing to the LanguageX service you agree to our',
        PayErpDialog_bottom_tips_2: ' Service Agreement',
        PayErpDialog_bottom_tips_3: ' and',
        PayErpDialog_bottom_tips_4: ' Privacy Policy',
        PayErpDialog_pay_fail_message: 'Payment Failed',
        PayErpDialog_orgName_verification_message: 'Please fill in the full name of your Organization!',
        PayErpDialog_orgName_LOX_verification_message:
            'Please contact the current enterprise/education version administrator to modify the name and try again',
        PayProDialog_title: 'Subscribe to PRO Version',
        PayProDialog_success_message_1: 'Subscription Successful',
        PayProDialog_success_message_2: 'Thank you for your trust and welcome to join the user group!',
        PayProDialog_success_message_3: 'Scan the QR code to join the official user group',
        PayProDialog_button_apply_invoice: 'Request for Invoice',
        PayProDialog_currency_symbol: '￥',
        PayProDialog_label_time_limit: 'Time Period',
        PayProDialog_form_orderNo_label: 'Order Number',
        PayProDialog_form_amount_label: 'Payment Amount',
        PayProDialog_form_scan_label: 'Scan to pay',
        PayProDialog_form_scan_platform_wechat: 'WeChat',
        PayProDialog_form_org_benefits_tips: 'Learn more about PRO Version',
        PayProDialog_bottom_tips_1: 'By subscribing to the LanguageX service you agree to our',
        PayProDialog_bottom_tips_2: ' Service Agreement',
        PayProDialog_bottom_tips_3: ' and',
        PayProDialog_bottom_tips_4: ' Privacy Policy',
        PayProDialog_pay_fail_message: 'Payment Failed',
        QaSetting_title: 'QA Settings',
        QaSetting_type_title_1: 'QA Setting Types',
        QaSetting_type_title_2: 'Intelligent QA Settings',
        QaSetting_support_tooltip_content: 'Upgrade to a higher version with Intelligent QA',
        QaSetting_fluency_tips:
            'Fluency in English (including word choice, verb tense, singular/plural, capitalization, pronouns/articles, English fluency check)',
        QaSetting_button_cancel: 'Cancel',
        QaSetting_button_submit: 'Confirm',
        QaSetting_success_message: 'QA settings have been updated',
        UpgradePro_message_1: 'Please complete the account upgrade first',
        UpgradePro_pro_limited_label: 'Expired',
        UpgradePro_message_2:
            'This feature is not available for the version currently in use. Upgrade your account to unlock more advanced features.',
        UpgradePro_button_upgrade: 'Upgrade to PRO',
        UploadFiles_file_upload_status_fail: 'Failed',
        UploadFiles_file_upload_status_success: 'Success',
        UploadFiles_file_accept_message: 'Support files in {accept} formats',
        UploadFiles_file_limit_message: 'You can only upload up to {limit} files',
        UploadFiles_file_size_message: '{name} larger than {size}MB.',
        UploadFiles_file_exist_message: '{name} already exists',
        UploadFiles_login_expired_message: 'Your Session Has Expired. Please Login Again to Continue.',
        UploadFiles_file_upload_fail_message: '{name} upload failed, please try again',
        UploadOss_title: 'Upload File',
        UploadOss_file_accept_message: 'Support files in {accept} formats',
        UploadOss_file_limit_message: 'You can only upload up to {limit} files',
        UploadOss_file_empty_message: 'Uploading empty files is not supported',
        UploadOss_file_size_message: '{name} larger than {size}MB.',
        UploadOss_file_exist_message: '{name} already exists',
        UploadOss_file_upload_fail_message: 'File upload failed, please try again',
        UserSelect_select_placeholder: 'Select',
        UserSelector_search_input_placeholder: 'Enter keyword to search',
        UserSelector_empty_description: 'No content available',
        UserSelectorDialog_title: 'Assign Translators',
        UserSelectorDialog_form_name_label: 'Translator Name',
        UserSelectorDialog_form_name_placeholder: 'Select',
        UserSelectorDialog_button_cancel: 'Cancel',
        UserSelectorDialog_button_submit: 'Confirm',
    },
    engine: {
        config_page_title: 'Machine Translation Engines',
        config_page_button_term_setting: 'Terminology Customization',
        config_page_source_input_placeholder:
            'Paste a paragraph and compare the translation results from different engines quickly, up to 2000 words at a time.',
        config_page_button_change: 'Switch',
        config_page_button_try: 'Try the sample',
        config_page_button_upload: 'Upload File',
        config_page_tooltip_engine_detail: 'View Engine Details',
        config_page_tooltip_engine_create: 'Create New Engine',
        config_page_message_1: 'In addition to common engines, you can also ',
        config_page_message_2: 'train your personalized engines',
        config_page_message_3:
            'There is no personalized engine for the current language direction, try creating one yourself.',
        config_page_message_4:
            'Your PRO account has expired and the personalized engine you created has been suspended. Service will be resumed upon renewal.',
        config_page_message_5: 'Go to Renewal Page',
        config_page_message_6: 'Translation in progress. Please wait.',
        config_page_message_7:
            'Personalized Engine has been taken down due to expiration of membership, service will be restored after renewal.',
        config_page_message_8: 'Exclusive engine for PRO version. Upgrade to PRO version to use.',
        config_page_message_9: 'Upgrade',
        config_page_message_10: 'Copied to clipboard',
        config_page_message_11: 'Copy to clipboard failed. Please try again.',
        config_page_message_12: 'Select Source Language',
        config_page_message_13: 'Terminology customization is on',
        config_page_message_14: 'Terminology customization is off',
        config_page_message_15: 'This term is not available in the current language',
        config_page_message_16: 'Original text:',
        config_page_message_17: 'Polish up to {count} times',
        config_page_button_copy: 'Copy',
        config_page_button_edit: 'Edit',
        config_page_button_show_difference: 'Show differences',
        config_page_button_clear_history: 'Clear History',
        config_page_button_polish: 'Polish',
        config_page_button_polish_again: 'Polish Again',
        config_page_term_title: 'Terminology Customization',
        config_page_term_descoption:
            'Try adding terms, up to 20 per language. To add terms and translation files in bulk, click Upload Files to create a new project.',
        config_page_term_table_label_1: 'No.',
        config_page_term_table_label_2: 'Original text ({name})',
        config_page_term_table_label_3: 'Translation ({name})',
        config_page_term_table_label_4: 'Setting',
        config_page_term_table_input_1: 'Enter the original term',
        config_page_term_table_input_2: 'Enter a translation of the term',
        config_page_term_table_button_1: 'Save (Enter)',
        config_page_term_table_button_2: 'Delete',
        config_page_term_table_button_3: 'Edit',
        config_page_term_verification_message_1: 'The original term cannot be empty',
        config_page_term_verification_message_2: 'The translation of term cannot be empty',
        config_page_term_message_1: 'Saved Successfully',
        config_page_term_message_2: 'Term Deleted',
        ragCreate_page_title: 'Custom RAG MT Model',
        ragCreate_page_alert_1_part1:
            '{type} can create up to {size} personalized engines, and your account has reached the limit.',
        ragCreate_page_alert_1_part2: 'If you want to create a new personalized engine, please contact us.',
        ragCreate_page_alert_2:
            'Retrieval-Augmented Generation (RAG) allows large language models to combine translation memory/glossary and specific requirements to translate vertical domain content more professionally.',
        ragCreate_page_form_model_label: 'Model Name',
        ragCreate_page_form_model_placeholder: 'Name your own translation engine, no more than 10 characters',
        ragCreate_page_form_language_label: 'Language Direction',
        ragCreate_page_form_language_source_placeholder: 'Source Language',
        ragCreate_page_form_language_target_placeholder: 'Target Language',
        ragCreate_page_form_term_label: 'Specify Terminology Translation',
        ragCreate_page_form_term_tips:
            'Specify some project-specific core terms without uploading a glossary (Please upload the glossary if there are many terms)',
        ragCreate_page_form_term_source_placeholder: 'Enter the original term',
        ragCreate_page_form_term_target_placeholder: 'Enter a translation of the term',
        ragCreate_page_form_demand_label: 'Other Requirements',
        ragCreate_page_form_demand_tooltip_content:
            'Please use short and concise language to describe specific requirements, here are some examples:<br>1. Use % for percent sign, no need to translate to percent. <br>2. Do not translate company names that appear in the text, keep the original names. <br>3. If the text contains serial numbers, translate "No. 1" as "I.".',
        ragCreate_page_form_demand_placeholder:
            'Please use short and concise language to describe specific requirements, here are some examples: 1. Use % for percent sign, no need to translate to percent. 2. Do not translate company names that appear in the text, keep the original names. 3. If the text contains serial numbers, translate "No. 1" as "I.".',
        ragCreate_page_form_description_label: 'Model Introduction',
        ragCreate_page_form_description_placeholder:
            'Please provide a brief introduction, within 100 words. For example: The international engineering field, with the language data mainly from engineering technical documents.',
        ragCreate_page_form_memoryFile_label: 'Upload Translation Memory',
        ragCreate_page_form_memoryFile_tips:
            "The language direction should be the same as the engine's language direction",
        ragCreate_page_form_memoryFile_message:
            'Upload translation memory in xlsx/xls/tmx format.<br>Up to 50 files per batch, with a maximum size of {size}MB per file.',
        ragCreate_page_form_termFile_label: 'Upload Glossary',
        ragCreate_page_form_termFile_tips:
            "The language direction should be the same as the engine's language direction",
        ragCreate_page_form_termFile_message:
            'Upload glossary in xlsx/xls/tbx format.<br>Up to 50 files per batch, with a maximum size of {size}MB per file.',
        ragCreate_page_button_submit: 'Generate Model',
        ragCreate_page_button_submit_message: 'Expected within 1 minute',
        ragCreate_page_success_dialog_title: 'Generation Completed',
        ragCreate_page_success_dialog_content_1: 'The {name} model has been successfully generated,',
        ragCreate_page_success_dialog_content_2: ' you can use it under Personalized Engines.',
        ragCreate_page_success_dialog_content_3: 'Please select the correct language direction before use.',
        ragCreate_page_success_dialog_button: 'Try it Out',
        ragCreate_page_language_zh_name: 'Chinese',
        ragCreate_page_language_zh_shortName: 'Zh',
        ragCreate_page_language_en_name: 'English',
        ragCreate_page_language_en_shortName: 'En',
        ragCreate_page_language_ko_name: 'Korean',
        ragCreate_page_language_ko_shortName: 'Ko',
        ragCreate_page_form_verification_message_1: 'Please Enter Model Name',
        ragCreate_page_form_verification_message_2: 'Upload Translation Memory',
        ragCreate_page_form_verification_message_3: 'Add up to 20 terms',
        create_page_title: 'Deep Training',
    },
    project: {
        task_page_tab_label_1: 'All',
        task_page_tab_label_2: 'Pending Acceptance',
        task_page_tab_label_3: 'In Progress',
        task_page_tab_label_4: 'Submitted',
        task_page_tab_label_5: 'Expired',
        task_page_search_kw_placeholder: 'Enter Task Name',
        task_page_search_source_placeholder: 'Source Lang',
        task_page_search_target_placeholder: 'Target Lang',
        task_page_search_taskType_placeholder: 'Task Type',
        task_page_search_order_placeholder: 'Sort By',
        task_page_search_button_text_1: 'Batch Accept',
        task_page_search_button_text_2: 'Batch Reject',
        task_page_search_button_text_3: 'Refresh',
        task_page_search_switch_button_text_1: 'Toggle',
        task_page_search_switch_button_text_2: ' List',
        task_page_search_switch_button_text_3: ' Card',
        task_page_search_switch_button_text_4: ' View',
        task_page_card_item_label_1: 'File Name:',
        task_page_card_item_label_2: 'Start:',
        task_page_card_item_label_3: 'Words',
        task_page_card_item_label_4: 'Deadline:',
        task_page_card_item_label_5: 'Reject',
        task_page_card_item_label_6: 'Accept',
        task_page_card_item_label_7: '%',
        task_page_card_item_label_8: 'Export',
        task_page_card_item_label_9: 'Import',
        task_page_table_colum_label_1: 'Task Name',
        task_page_table_colum_label_2: 'Task Type',
        task_page_table_colum_label_3: 'Segments',
        task_page_table_colum_label_4: 'Project Name',
        task_page_table_colum_label_5: 'Language Direction',
        task_page_table_colum_label_6: 'Billable Word Count',
        task_page_table_colum_label_7: 'Deadline',
        task_page_table_colum_label_8: 'Operations',
        task_page_table_colum_label_9: 'Unit Price',
        task_page_table_colum_label_10: 'Total Price',
        task_page_table_colum_label_11: 'Status',
        task_page_empty_label_1: 'There are no tasks ~',
        task_page_dialog_title_1: 'Accept Task',
        task_page_dialog_message_1: 'Are you sure you want to accept the following tasks?',
        task_page_dialog_btn_label_1: 'Cancel',
        task_page_dialog_btn_label_2: 'Confirm',
        task_page_dialog_title_2: 'Reject Task',
        task_page_dialog_message_2: 'Are you sure you want to reject the following tasks?',
        task_page_dialog_input_placeholder_1: 'Please provide a reason for rejection (required)',
        task_page_dialog_title_3: 'Import Translation',
        task_page_dialog_message_3: 'Are you sure you want to import the translation of the following tasks?',
        task_page_orderOptions_item_1: 'Sort by file name ascending',
        task_page_orderOptions_item_2: 'Sort by file name descending',
        task_page_orderOptions_item_3: 'Sort by start time ascending',
        task_page_orderOptions_item_4: 'Sort by start time descending',
        task_page_orderOptions_item_5: 'Sort by deadline ascending',
        task_page_orderOptions_item_6: 'Sort by deadline descending',
        task_page_validation_message_1: 'Please select a task first',
        task_page_validation_message_2: 'Task has been rejected',
        task_page_validation_message_3: 'Please select a task first',
        task_page_validation_message_4: 'Task has been accepted',
        task_statistics_page_search_input_placeholder: 'Enter task name',
        task_statistics_page_search_select_placeholder_1: 'Source Language',
        task_statistics_page_search_select_placeholder_2: 'Target Language',
        task_statistics_page_search_select_placeholder_3: 'Task Type',
        task_statistics_page_search_select_placeholder_4: 'Task Status',
        task_statistics_page_search_select_placeholder_5: 'Sort By',
        task_statistics_page_search_button_text_1: 'Refresh',
        task_statistics_page_orderOptions_item_1: 'Sort by file name ascending',
        task_statistics_page_orderOptions_item_2: 'Sort by file name descending',
        task_statistics_page_orderOptions_item_3: 'Sort by start time ascending',
        task_statistics_page_orderOptions_item_4: 'Sort by start time descending',
        task_statistics_page_orderOptions_item_5: 'Sort by deadline ascending',
        task_statistics_page_orderOptions_item_6: 'Sort by deadline descending',
        task_statistics_page_statusOptions_item_1: 'Pending Acceptance',
        task_statistics_page_statusOptions_item_2: 'Rejected',
        task_statistics_page_statusOptions_item_3: 'In Progress',
        task_statistics_page_statusOptions_item_4: 'Pending Submission',
        task_statistics_page_statusOptions_item_5: 'Submitted',
        task_statistics_page_table_column_lable_1: 'No.',
        task_statistics_page_table_column_lable_2: 'Task Name',
        task_statistics_page_table_column_lable_3: 'Task Type',
        task_statistics_page_table_column_lable_4: 'Project Name',
        task_statistics_page_table_column_lable_5: 'Language Direction',
        task_statistics_page_table_column_lable_6: 'Task Period',
        task_statistics_page_table_column_lable_7: 'Task Status',
        task_statistics_page_table_column_lable_8: 'Task Progress',
        task_statistics_page_table_column_lable_9: 'Original Word Count',
        task_statistics_page_table_column_lable_10: 'Billable Word Count',
        task_statistics_page_table_column_lable_11: 'Unit Price',
        task_statistics_page_table_column_lable_12: 'Total Price',
        task_statistics_page_table_column_tips_label_1: 'File Name:',
        task_statistics_page_table_column_tips_label_2: 'Start:',
        task_statistics_page_table_column_tips_label_3: 'No task start time yet',
        task_statistics_page_table_column_tips_label_4: 'No task end time yet',
        task_statistics_page_table_column_tips_label_5: '%',
    },
    asset: {
        memory_index_page_button_text_1: 'Create Translation Memory',
        memory_index_page_button_text_2: 'Merge Translation Memories',
        memory_index_page_search_input_placeholder: 'Full-text Search',
        memory_index_page_search_select_placeholder_1: 'Source Language',
        memory_index_page_search_select_placeholder_2: 'Target Language',
        memory_index_page_search_select_placeholder_3: 'Filter by Team',
        memory_index_page_card_item_label_1: 'Translation Memory Name: ',
        memory_index_page_card_item_label_2: 'Team Owner: ',
        memory_index_page_card_item_label_3: 'Creator: ',
        memory_index_page_card_item_label_4: 'Entries',
        memory_index_page_card_item_dropdown_label_1: 'View',
        memory_index_page_card_item_dropdown_label_2: 'Download Excel',
        memory_index_page_card_item_dropdown_label_3: 'Download TMX',
        memory_index_page_card_item_dropdown_label_4: 'Rename',
        memory_index_page_card_item_dropdown_label_5: 'Delete',
        memory_index_page_empty_label_1: 'No translation memories found～',
        memory_index_page_empty_label_2: 'No translation memories yet, go create one～',
        memory_index_page_dialog_1_title: 'Rename Translation Memory',
        memory_index_page_dialog_1_form_item_label_1: 'Name',
        memory_index_page_dialog_1_form_item_placeholder_1: 'Enter translation memory name',
        memory_index_page_dialog_1_btn_label_1: 'Cancel',
        memory_index_page_dialog_1_btn_label_2: 'Save',
        memory_index_page_dialog_2_title: 'Merge Translation Memories',
        memory_index_page_dialog_2_form_item_label_1: 'Translation Memory Name',
        memory_index_page_dialog_2_form_item_placeholder_1: 'Enter translation memory name',
        memory_index_page_dialog_2_form_item_checkbox_label_2: 'Delete original translation memories after merge',
        memory_index_page_dialog_2_btn_label_1: 'Cancel',
        memory_index_page_dialog_2_btn_label_2: 'Merge',
        memory_index_page_alert_info_message_1: 'Are you sure you want to delete this translation memory?',
        memory_index_page_alert_info_buttonName_1: 'Confirm Deletion',
        memory_index_page_validation_message_1: 'Permission denied',
        memory_index_page_validation_message_2: 'Translation memory name is required',
        memory_index_page_validation_message_3: 'Translation memory renamed successfully',
        memory_index_page_validation_message_4:
            'Please select two or more translation memories with the same language direction to merge',
        memory_index_page_validation_message_5:
            'Please select translation memories with the same language direction to merge',
        memory_index_page_validation_message_6: 'Translation memory name cannot be empty',
        memory_index_page_validation_message_7: 'Translation memories have been merged',
        memory_index_page_validation_message_8: 'Translation memory has been deleted',
        memory_detail_page_fullLoading_message: 'Loading...',
        memory_detail_page_more_info_form_item_label_1: 'Language Direction',
        memory_detail_page_more_info_form_item_label_2: 'Number of Entries',
        memory_detail_page_more_info_form_item_label_2_1: 'Entries',
        memory_detail_page_more_info_form_item_label_3: 'Creation Time',
        memory_detail_page_search_input_placeholder: 'Enter translation memory source or target text',
        memory_detail_page_search_btn_label_1: 'Delete',
        memory_detail_page_search_btn_label_2: 'Upload Translation Memory',
        memory_detail_page_search_btn_label_3: 'Download',
        memory_detail_page_search_btn_label_4: 'Download Excel',
        memory_detail_page_search_btn_label_5: 'Download TMX',
        memory_detail_page_table_colum_label_1: 'No.',
        memory_detail_page_table_colum_label_2: 'Source Text ({value})',
        memory_detail_page_table_colum_label_3: 'Target Text ({value})',
        memory_detail_page_table_colum_label_4: 'Operations',
        memory_detail_page_table_colum_input_placeholder_1: 'Enter source text',
        memory_detail_page_table_colum_input_placeholder_2: 'Enter target text',
        memory_detail_page_table_colum_btn_tips_1: 'Save',
        memory_detail_page_table_colum_btn_tips_2: 'Delete',
        memory_detail_page_table_colum_btn_tips_3: 'Edit',
        memory_detail_page_dialog_title_1: 'Upload Translation Memory',
        memory_detail_page_dialog_message_1: 'Support {value}, click to download template: ',
        memory_detail_page_dialog_1_btn_1: 'Cancel',
        memory_detail_page_dialog_1_btn_2: 'Confirm',
        memory_detail_page_alertInfo_message: 'Are you sure you want to delete these translation memories?',
        memory_detail_page_alertInfo_buttonName: 'Confirm Deletion',
        memory_detail_page_downloadTemplate_message: 'Downloading...',
        memory_detail_page_validation_message_1: 'Failed to get download address',
        memory_detail_page_validation_message_2: 'Source text cannot be empty',
        memory_detail_page_validation_message_3: 'Target text cannot be empty',
        memory_detail_page_validation_message_4: 'Translation memory saved successfully',
        memory_detail_page_validation_message_5: 'Source text cannot be empty',
        memory_detail_page_validation_message_6: 'Target text cannot be empty',
        memory_detail_page_validation_message_7: 'Translation memory saved successfully',
        memory_detail_page_validation_message_8: 'Translation memory name is required',
        memory_detail_page_validation_message_9: 'Translation memory renamed successfully',
        memory_detail_page_validation_message_10: 'Translation memory has been deleted',
        memory_detail_page_validation_message_11: 'Please confirm file upload is complete and successful',
        memory_detail_page_validation_message_12: 'Upload successful',
        term_index_page_search_btn_label_1: 'Create Termbase',
        term_index_page_search_btn_label_2: 'Merge Termbases',
        term_index_page_search_input_placeholder: 'Full-text Search',
        term_index_page_search_select_1_placeholder: 'Source Language',
        term_index_page_search_select_2_placeholder: 'Target Language',
        term_index_page_search_select_3_placeholder: 'Filter by Team',
        term_index_page_item_tips_1: 'Termbase Name',
        term_index_page_item_tips_2: 'Team Owner: ',
        term_index_page_item_tips_3: 'Creator: ',
        term_index_page_item_tips_4: 'Entries',
        term_index_page_item_dropdown_label_1: 'View',
        term_index_page_item_dropdown_label_2: 'Download Excel',
        term_index_page_item_dropdown_label_3: 'Download TBX',
        term_index_page_item_dropdown_label_4: 'Rename',
        term_index_page_item_dropdown_label_5: 'Delete',
        term_index_page_empty_message_1: 'No termbases found～',
        term_index_page_empty_message_2: 'No termbases yet, go create one～',
        term_index_page_dialog_1_title: 'Rename Termbase',
        term_index_page_dialog_1_form_item_label_1: 'Name',
        term_index_page_dialog_1_form_item_placeholder_1: 'Enter termbase name',
        term_index_page_dialog_1_btn_label_1: 'Cancel',
        term_index_page_dialog_1_btn_label_2: 'Save',
        term_index_page_dialog_2_title: 'Merge Termbases',
        term_index_page_dialog_2_form_item_label_1: 'Termbase Name',
        term_index_page_dialog_2_form_item_placeholder_1: 'Enter termbase name',
        term_index_page_dialog_2_form_item_checkbox_label_1: 'Delete original termbases after merge',
        term_index_page_dialog_2_btn_label_1: 'Cancel',
        term_index_page_dialog_2_btn_label_2: 'Save',
        term_index_page_alertInfo_message: 'Are you sure you want to delete this termbase?',
        term_index_page_alertInfo_buttonName: 'Confirm Deletion',
        term_index_page_validation_message_1: 'Permission denied',
        term_index_page_validation_message_2: 'Termbase name is required',
        term_index_page_validation_message_3: 'Termbase renamed successfully',
        term_index_page_validation_message_4:
            'Please select two or more termbases with the same language direction to merge',
        term_index_page_validation_message_5: 'Please select termbases with the same language direction to merge',
        term_index_page_validation_message_6: 'Termbase name cannot be empty',
        term_index_page_validation_message_7: 'Termbases have been merged',
        term_index_page_validation_message_8: 'Termbases have been deleted',
        term_detail_page_more_info_form_item_label_1: 'Language Direction',
        term_detail_page_more_info_form_item_label_2: 'Number of Entries',
        term_detail_page_more_info_form_item_label_2_1: 'Entries',
        term_detail_page_more_info_form_item_label_3: 'Creation Time',
        term_detail_page_search_input_placeholder: 'Enter term',
        term_detail_page_search_btn_label_1: 'Delete',
        term_detail_page_search_btn_label_2: 'Upload Terminology',
        term_detail_page_search_btn_label_3: 'Download',
        term_detail_page_search_btn_label_4: 'Download Excel',
        term_detail_page_search_btn_label_5: 'Download TBX',
        term_detail_page_table_colum_label_1: 'No.',
        term_detail_page_table_colum_label_2: 'Source Text ({value})',
        term_detail_page_table_colum_label_3: 'Target Text ({value})',
        term_detail_page_table_colum_label_4: 'Remarks',
        term_detail_page_table_colum_label_5: 'Operations',
        term_detail_page_table_colum_input_placeholder_1: 'Enter term source text',
        term_detail_page_table_colum_input_placeholder_2: 'Enter term target text',
        term_detail_page_table_colum_input_placeholder_3: 'Enter remarks',
        term_detail_page_table_colum_btn_tips_1: 'Save',
        term_detail_page_table_colum_btn_tips_2: 'Delete',
        term_detail_page_table_colum_btn_tips_3: 'Edit',
        term_detail_page_dialog_title_1: 'Upload Termbase',
        term_detail_page_dialog_message_1: 'Support {value}, click to download template: ',
        term_detail_page_dialog_1_btn_1: 'Cancel',
        term_detail_page_dialog_1_btn_2: 'Confirm',
        term_detail_page_alertInfo_message: 'Are you sure you want to delete these terms?',
        term_detail_page_alertInfo_buttonName: 'Confirm Deletion',
        term_detail_page_downloadTemplate_message: 'Downloading...',
        term_detail_page_validation_message_1: 'Term source text cannot be empty',
        term_detail_page_validation_message_2: 'Term target text cannot be empty',
        term_detail_page_validation_message_3: 'Term saved successfully',
        term_detail_page_validation_message_4: 'Term source text cannot be empty',
        term_detail_page_validation_message_5: 'Term target text cannot be empty',
        term_detail_page_validation_message_6: 'Term saved successfully',
        term_detail_page_validation_message_7: 'Termbase name is required',
        term_detail_page_validation_message_8: 'Termbase renamed successfully',
        term_detail_page_validation_message_9: 'Term has been deleted',
        term_detail_page_validation_message_10: 'Failed to get download address',
        term_detail_page_validation_message_11: 'Please confirm file upload is complete and successful',
        term_detail_page_validation_message_12: 'Upload successful',
        component_FulltextSearch_dialog_title_1: 'Full-text Search in Translation Memory',
        component_FulltextSearch_dialog_title_2: 'Full-text Search in Termbase',
        component_FulltextSearch_search_input_placeholder: 'Enter keyword',
        component_FulltextSearch_search_select_placeholder: 'Please select',
        component_FulltextSearch_search_select_option_1: 'Search by title',
        component_FulltextSearch_search_select_option_2: 'Search by content',
        component_FulltextSearch_search_btn_label: 'Start Search',
        component_FulltextSearch_search_result_total: 'Search results {total} items',
        component_FulltextSearch_search_result_param_name_1: 'Team Owner: ',
        component_FulltextSearch_search_result_param_name_2: 'Creator: ',
        component_FulltextSearch_search_result_param_name_3: 'Language Direction: ',
        component_FulltextSearch_search_result_param_name_4: 'Modification Time: ',
        component_FulltextSearch_search_result_param_label_1: 'Entries',
        component_FulltextSearch_search_result_param_label_2: 'Title: ',
        component_FulltextSearch_search_result_param_label_3: 'Source Text: ',
        component_FulltextSearch_search_result_param_label_4: 'Target Text: ',
        component_FulltextSearch_search_result_message_1: 'Loading...',
        component_FulltextSearch_search_result_message_2: 'All results displayed',
        component_FulltextSearch_validation_message_1: 'Please enter search content',
        component_Tabs_tab_name_1: 'Translation Memory',
        component_Tabs_tab_name_2: 'Termbase',
        component_CreateMemory_dialog_title: 'Create Translation Memory',
        component_CreateMemory_form_item_label_1: 'Name',
        component_CreateMemory_form_item_placeholder_1: 'Enter translation memory name',
        component_CreateMemory_form_item_label_2: 'Language Pair',
        component_CreateMemory_form_item_placeholder_2: 'Source Language',
        component_CreateMemory_form_item_placeholder_3: 'Target Language',
        component_CreateMemory_form_item_label_3: 'Team Owner',
        component_CreateMemory_form_item_placeholder_4: 'Select Team',
        component_CreateMemory_form_item_label_4: 'Upload Translation Memory',
        component_CreateMemory_form_item_message_1: 'Support {type}, max {size}M. Click to download template: ',
        component_CreateMemory_dialog_btn_label_1: 'Cancel',
        component_CreateMemory_dialog_btn_label_2: 'Save',
        component_CreateMemory_downloadTemplate_message: 'Downloading...',
        component_CreateMemory_validation_message_1: 'Failed to get download address',
        component_CreateMemory_validation_message_2: 'Translation memory name is required',
        component_CreateMemory_validation_message_3:
            'Source and target languages for the language pair must be selected',
        component_CreateMemory_validation_message_4: 'Translation memory created successfully',
        component_CreateTerm_dialog_title: 'Create Termbase',
        component_CreateTerm_form_item_label_1: 'Name',
        component_CreateTerm_form_item_placeholder_1: 'Enter termbase name',
        component_CreateTerm_form_item_label_2: 'Language Pair',
        component_CreateTerm_form_item_placeholder_2: 'Source Language',
        component_CreateTerm_form_item_placeholder_3: 'Target Language',
        component_CreateTerm_form_item_label_3: 'Team Owner',
        component_CreateTerm_form_item_placeholder_4: 'Select Team',
        component_CreateTerm_form_item_label_4: 'Upload Terms',
        component_CreateTerm_form_item_message_1: 'Support {type}, max {size}M. Click to download template: ',
        component_CreateTerm_dialog_btn_label_1: 'Cancel',
        component_CreateTerm_dialog_btn_label_2: 'Save',
        component_CreateTerm_downloadTemplate_message: 'Downloading...',
        component_CreateTerm_validation_message_1: 'Failed to get download address',
        component_CreateTerm_validation_message_2: 'Termbase name is required',
        component_CreateTerm_validation_message_3: 'Source and target languages for the language pair must be selected',
        component_CreateTerm_validation_message_4: 'Termbase created successfully',
    },
    tools: {},
    system: {
        slogan: 'New Generation of AI Translation Platform',
        basic_left_tagline_1: '35 Global Translation Engines for Ultimate AI Personalization',
        basic_left_tagline_2: 'Powerful, User-Friendly AI Tools Built on Large Language Models',
        basic_left_tagline_3: 'Streamlined Translation Management and Multi-User Collaboration',
        login_page_service_terms: 'Service Agreement',
        login_page_privacy_policy: 'Privacy Policy',
        login_page_title: 'Login',
        login_page_form_title: 'LOGIN',
        login_page_tab_phone: 'Phone',
        login_page_tab_account: 'Email or username',
        login_page_form_phone_placeholder: 'Phone number',
        login_page_form_account_placeholder: 'Email or username',
        login_page_form_password_placeholder: 'Password',
        login_page_form_button: 'Log in',
        login_page_link_forgot: 'Forgot password',
        login_page_link_signup: 'Sign up',
        login_page_link_password: 'Use password',
        login_page_link_login: 'Log in',
        login_page_wechat: 'Use Wechat',
        login_page_wechat_title: 'Log in with WeChat',
        login_page_wechat_subtitle: 'Open WeChat and scan to log in',
        login_page_wechat_reload: 'Refresh',
        login_page_wechat_qrcode_overdue: 'QR Code Expired',
        login_page_bindphone_title: 'Bind mobile number',
        login_page_bindphone_form_phone_placeholder: 'Phone number',
        login_page_bindphone_form_captcha_placeholder: 'Verification code',
        login_page_bindphone_form_send_captcha: 'Send',
        login_page_bindphone_form_send_captcha_seconds: 's',
        login_page_form_verification_message_1: 'Username and password cannot be empty',
        login_page_form_verification_message_2: 'Phone number',
        login_page_form_verification_message_3: 'Invalid phone number',
        login_page_form_verification_message_4: 'Phone number and password cannot be empty',
        login_page_form_sent_captcha_message: 'The verification code has been sent',
        login_page_bindphone_form_verification_message_1: 'Phone number',
        login_page_bindphone_form_verification_message_2: 'Invalid phone number',
        login_page_bindphone_form_verification_message_3: 'Verification code',
        forget_page_title: 'Forgot password',
        forget_page_form_title: 'Forgot password',
        forget_page_form_phone_placeholder: 'Phone number',
        forget_page_form_captcha_placeholder: 'Verification code',
        forget_page_form_password_placeholder: 'Enter new password',
        forget_page_form_button: 'Confirm',
        forget_page_form_verification_message_1: 'Phone number',
        forget_page_form_verification_message_2: 'Invalid phone number',
        forget_page_form_verification_message_3: 'Verification code',
        forget_page_form_verification_message_4: 'Password',
        forget_page_form_verification_message_5:
            'The password must be between 6 to 20 characters and must contain lowercase letters and numbers',
        forget_page_form_submit_success: 'Password changed successfully. Redirect to login page after 1s.',
        forget_page_form_sent_captcha_message: 'The verification code has been sent',
        signup_page_title: 'Sign up',
        signup_page_form_title: 'Sign up with phone',
        signup_page_form_phone_placeholder: 'Phone number',
        signup_page_form_captcha_placeholder: 'Verification code',
        signup_page_form_password_placeholder: 'Password',
        signup_page_form_invite_code_placeholder: 'Invitation code (optional)',
        signup_page_form_send_captcha: 'Send',
        signup_page_form_send_captcha_seconds: 's',
        signup_page_form_clause_prefix: 'By continuing, you agree to',
        signup_page_form_button: 'Sign up',
        signup_page_form_verification_message_1: 'Phone number',
        signup_page_form_verification_message_2: 'Invalid phone number',
        signup_page_form_verification_message_3: 'Verification code',
        signup_page_form_verification_message_4: 'Password',
        signup_page_form_verification_message_5:
            'The password must be between 6 to 20 characters and must contain lowercase letters and numbers',
        signup_page_form_submit_success: 'Successful signup, entering the system automatically after 2s.',
        signup_page_form_sent_captcha_message: 'The verification code has been sent',
        check_page_title: 'Switch to Enterprise/Education version',
        check_page_switch_header: 'Switch to Enterprise/Education version',
        check_page_switch_message_1: 'You are currently logging in,',
        check_page_switch_message_2: 'are you sure you want to switch to',
        check_page_switch_message_3: 'Enterprise/Education version?',
        check_page_switch_button_1: 'Cancel',
        check_page_switch_button_2: 'Confirm',
    },
    account: {},
    workbench: {
        editer_page_dialog_1_title: 'Filter Segments',
        editer_page_dialog_1_search_input_label: 'Keywords',
        editer_page_dialog_1_search_input_placeholder: 'Enter keywords',
        editer_page_dialog_1_group_1_title: 'Confirmation',
        editer_page_dialog_1_group_1_item_1_label: 'All',
        editer_page_dialog_1_group_1_item_2_label: 'Unconfirmed',
        editer_page_dialog_1_group_1_item_3_label: 'Confirmed',
        editer_page_dialog_1_group_2_title: 'Lock',
        editer_page_dialog_1_group_2_item_1_label: 'All',
        editer_page_dialog_1_group_2_item_2_label: 'Unlocked',
        editer_page_dialog_1_group_2_item_3_label: 'Locked',
        editer_page_dialog_1_group_3_title: 'Translation Source',
        editer_page_dialog_1_group_3_item_1_label: 'All',
        editer_page_dialog_1_group_3_item_2_label: 'Translation Memory',
        editer_page_dialog_1_group_3_item_3_label: 'Machine Translation',
        editer_page_dialog_1_group_4_title: 'QA',
        editer_page_dialog_1_group_4_item_1_label: 'All',
        editer_page_dialog_1_group_4_item_2_label: 'No QA Issues',
        editer_page_dialog_1_group_4_item_3_label: 'QA Issues Found',
        editer_page_dialog_1_group_5_title: 'Comments',
        editer_page_dialog_1_group_5_item_1_label: 'All',
        editer_page_dialog_1_group_5_item_2_label: 'No Comments',
        editer_page_dialog_1_group_5_item_3_label: 'Comments',
        editer_page_dialog_1_btn_1_label: 'Reset',
        editer_page_dialog_1_btn_2_label: 'Confirm',
        editer_page_dialog_2_title: 'Prompt',
        editer_page_dialog_2_btn_1_label: 'Cancel',
        editer_page_dialog_2_btn_2_label: 'Return',
        editer_page_data_loadingText: 'Loading...',
        editer_page_methods_initPage_editorPermissionMessage_message: 'No editing permissions at the moment',
        editer_page_methods_saveDocuments_success_message: 'Saved successfully',
        editer_page_methods_applyJargon_error_message: 'The current segment is locked and cannot apply terminology',
        editer_page_methods_applyTranslate_error_message:
            'The current segment is locked and cannot apply machine translation',
        editer_page_methods_applyMemory_error_message:
            'The current segment is locked and cannot apply translation memory',
        editer_page_methods_reTranslate_loadingText: 'Retranslating all segments containing the term...',
        editer_page_methods_reTranslate_success_message_1: 'Retranslated {size} segments where the term is located',
        editer_page_methods_reTranslate_success_message_2: 'No terms found that require retranslation',
        editer_page_methods_repealOrRecoverBtnEvent_success_message_1: 'Revoked',
        editer_page_methods_repealOrRecoverBtnEvent_success_message_2: 'Recovered',
        editer_page_methods_setSentencesStyle_error_message: 'Only support {types} format for setting styles',
        editer_page_methods_setSplitMode_info_message:
            'The segment has entered split mode, determine the cursor position to split',
        editer_page_methods_submitSplit_error_message:
            'Splitting is not supported at the beginning or end of a sentence',
        editer_page_methods_submitSplit_success_message: 'Segment split successfully',
        editer_page_methods_submitMarge_error_message:
            'It is already the last segment on this page, cannot merge with the next',
        editer_page_methods_submitMarge_success_message: 'Segments merged',
        editer_page_methods_saveEditSource_success_message: 'Source text saved',
        editer_page_methods_qaApplyReferenceEvent_error_message: 'No editing permissions at the moment',
        editer_page_methods_startFillTags_success_message: 'Filled {size} tags for all translated segments',
        editer_page_methods_startFillTags_loadingText_1: 'AI tag filling in progress, please wait',
        editer_page_methods_startFillSentenceTags_error_message: 'Translation cannot be empty',
        editer_page_methods_startFillSentenceTags_info_message:
            'Extracting original text tags and inserting them into the corresponding position in the translation',
        editer_page_methods_setTaskStatus_success_message: 'Submitted successfully',
        editer_page_methods_setTaskStatus_loadingText: 'Submitting, please wait',
        editer_page_methods_quickSubmit_success_message: 'Quick confirmation successful',
        editer_page_methods_quickSubmit_loadingText: 'Quick confirming, please wait',
        editer_page_methods_downloadStyleGuide_file_title: 'Style Guide',
        editer_page_methods_executeAction_message_1: 'Select a segment before editing the source text',
        editer_page_methods_executeAction_message_2:
            'The segment is in source text mode, click on the source text to edit',
        editer_page_methods_executeAction_message_3: 'Exclusive feature of Pro version, upgrade to Pro to use.',
        editer_page_methods_executeAction_message_3_1: 'Upgrade now',
        editer_page_methods_executeAction_message_4: 'Tags cannot be filled for file types such as csv, txt, srt, etc.',
        editer_page_methods_beforeRouteLeave_warning_message: 'Auto-saving, please wait',
        component_Tools_pageTitle_form_item_label_1: 'Language Direction',
        component_Tools_pageTitle_engine_name_1: '{name} provides basic translation',
        component_Tools_pageTitle_engine_name_2: 'LanguageX provides interactive translation',
        component_Tools_pageTitle_save_tips_1: 'Saving...',
        component_Tools_pageTitle_save_tips_2: 'Saved',
        component_Tools_pageTitle_progress_tips: '{type} progress: {value}%',
        component_Tools_pageTitle_changeInteractiveMode_select_placeholder: 'Switch editing mode',
        component_Tools_pageTitle_changeInteractiveMode_select_option_1: 'Interact Trans.',
        component_Tools_pageTitle_changeInteractiveMode_select_option_2: 'Post-editing',
        component_Tools_pageTitle_submitTask_btn_label: 'Submit',
        component_Tools_pageTitle_rollbackTask_btn_label: 'Return',
        component_Tools_pageTitle_download_btn_label_1: 'Download',
        component_Tools_pageTitle_download_btn_label_2: 'Trans',
        component_Tools_pageTitle_download_btn_label_3: 'Dual',
        component_Tools_pageTitle_download_btn_label_4: 'Orig',
        component_Tools_pageTitle_download_btn_label_5: 'TMX',
        component_Tools_actionContent_btn_label_1: 'Save',
        component_Tools_actionContent_btn_label_2: 'Undo',
        component_Tools_actionContent_btn_label_3: 'Redo',
        component_Tools_actionContent_btn_label_4: 'Find/Replace',
        component_Tools_actionContent_btn_label_5: 'Search Translation Memory',
        component_Tools_actionContent_btn_label_6: 'Term Customization',
        component_Tools_actionContent_btn_label_7: 'Full-text Tagging',
        component_Tools_actionContent_btn_label_8: 'Confirm',
        component_Tools_actionContent_btn_label_9: 'Split',
        component_Tools_actionContent_btn_label_10: 'Merge',
        component_Tools_actionContent_btn_label_10_title: 'Merge next sentence into current',
        component_Tools_actionContent_btn_label_11: 'Edit',
        component_Tools_actionContent_btn_label_12: 'Copy Text',
        component_Tools_actionContent_btn_label_12_title: 'Fill the current segment source text into the target text',
        component_Tools_actionContent_btn_label_13: 'Copy Tags',
        component_Tools_actionContent_btn_label_13_title: 'Fill the current segment source tags into the target text',
        component_Tools_actionContent_btn_label_14: 'Get MT',
        component_Tools_actionContent_btn_label_15: 'Lock',
        component_Tools_actionContent_btn_label_16: 'Bold',
        component_Tools_actionContent_btn_label_17: 'Italic',
        component_Tools_actionContent_btn_label_18: 'Underline',
        component_Tools_actionContent_btn_label_19: 'Superscript',
        component_Tools_actionContent_btn_label_20: 'Subscript',
        component_Tools_actionContent_btn_label_21: 'Comment',
        component_Tools_actionContent_btn_label_22: 'Filter',
        component_Tools_actionContent_btn_label_23: 'QA Check',
        component_Tools_actionContent_btn_label_24: 'Style Guide',
        component_Tools_actionContent_btn_label_24_title: 'Download Style Guide',
        component_Tools_actionContent_btn_label_25: 'More Settings',
        component_Tools_actionContent_btn_label_26: 'Font Size',
        component_Tools_actionContent_btn_label_27: 'Shortcut Keys',
        component_Tools_actionContent_btn_label_28: 'Edit History',
        component_Tools_actionContent_btn_label_29: 'Collapse',
        component_Tools_actionContent_btn_label_30: 'Expand',
        component_Tools_dialog_1_title: 'Task List',
        component_Tools_dialog_1_table_column_1: 'Person in Charge',
        component_Tools_dialog_1_table_column_2: 'Task Type',
        component_Tools_dialog_1_table_column_3: 'Progress',
        component_Tools_dialog_1_table_column_4: 'Status',
        component_Tools_dialog_1_table_column_5: 'Return Records',
        component_Tools_dialog_1_table_message_1: 'Returned {size} times',
        component_Tools_dialog_2_title: 'Return Task',
        component_Tools_dialog_2_message_1: 'The task will be returned to',
        component_Tools_dialog_2_message_2: 'stage',
        component_Tools_dialog_2_form_item_label_1: 'Reason for Return',
        component_Tools_dialog_2_form_item_placeholder_1: 'Please fill in the reason for return',
        component_Tools_dialog_2_btn_1: 'Cancel',
        component_Tools_dialog_2_btn_2: 'Confirm',
        component_Tools_dialog_3_title: 'Font Size',
        component_Tools_dialog_3_message_1: 'Text Size',
        component_Tools_dialog_3_message_2: 'Small',
        component_Tools_dialog_3_message_3: 'Large',
        component_Tools_dialog_4_title: 'Shortcut Keys',
        component_Tools_dialog_4_table_column_1: 'Function',
        component_Tools_dialog_4_table_column_2: 'Shortcut Key',
        component_Tools_data_alertInfo_message_1:
            'Switching back to interactive translation mode will retain confirmed segments and clear unconfirmed segments, continue?',
        component_Tools_data_alertInfo_message_2:
            'Switching back to interactive translation mode will retain confirmed segments and clear unconfirmed segments, continue?',
        component_Tools_data_alertInfo_message_3: 'Confirm submission?',
        component_Tools_data_alertInfo_buttonName_1: 'Confirm',
        component_Tools_data_hotkeyList_name_1: 'Select Candidate Fragments',
        component_Tools_data_hotkeyList_name_2: 'Apply MT to Entire Sentence',
        component_Tools_data_hotkeyList_name_3: 'Confirm Translation',
        component_Tools_data_hotkeyList_name_4: 'Quick Add Term',
        component_Tools_data_hotkeyList_name_5: 'Translation Memory',
        component_Tools_method_progressFormat_message: 'Translator progress : {percentage}%',
        component_Tools_method_jargonSettingEvent_message_1: 'Exclusive feature of Pro version, upgrade to Pro to use.',
        component_Tools_method_jargonSettingEvent_message_2: 'Upgrade now',
        component_Tools_method_addEditFontSize_message: 'Already at maximum size',
        component_Tools_method_shrinkEditFontSize_message: 'Already at minimum size',
        component_Tools_method_getMTResult_message_1: 'Manual translation, cannot obtain Machine Translation',
        component_Tools_method_getMTResult_message_2: 'Please select a segment',
        component_Tools_method_rejectBtnEvent_message: 'Cannot return at the current stage',
        component_Tools_method_submitRejectEvent_message_1: 'Please fill in the reason for return',
        component_Tools_method_submitRejectEvent_message_2: 'Returned successfully',
        component_BasicEditerV3_tips_1: 'Click to unlock',
        component_BasicEditerV3_tips_2: 'Click to lock',
        component_BasicEditerV3_tips_3: 'Comment',
        component_BasicEditerV3_tips_4: 'Confirm split',
        component_BasicEditerV3_tips_5: 'Exit split mode',
        component_BasicEditerV3_tips_6: 'Save',
        component_BasicEditerV3_tips_7: 'Exit source text edit mode',
        component_BasicEditerV3_tips_8: 'View QA results',
        component_BasicEditerV3_tips_9: 'Confirm (Enter)',
        component_BasicEditerV3_tips_10: 'Confirm',
        component_BasicEditerV3_tips_11: 'Machine translation may have risks',
        component_BasicEditerV3_tips_12: 'LanguageX provides interactive translation',
        component_BasicEditerV3_tips_13: '{name} translation',
        component_BasicEditerV3_dialog_1_title: 'Quick Add Term',
        component_BasicEditerV3_dialog_1_form_item_label_1: 'Source Text',
        component_BasicEditerV3_dialog_1_form_item_placeholder_1: 'Please select or enter source text',
        component_BasicEditerV3_dialog_1_form_item_label_2: 'Target Text',
        component_BasicEditerV3_dialog_1_form_item_placeholder_2: 'Please select or enter target text',
        component_BasicEditerV3_dialog_1_form_item_label_3: 'Remarks',
        component_BasicEditerV3_dialog_1_form_item_placeholder_3: 'Please enter remarks',
        component_BasicEditerV3_dialog_1_btn_1: 'Cancel',
        component_BasicEditerV3_dialog_1_btn_2: 'Confirm',
        component_BasicEditerV3_data_previewTypeList_name_1: 'Trans',
        component_BasicEditerV3_data_previewTypeList_name_2: 'Dual',
        component_BasicEditerV3_data_previewTypeList_name_3: 'Orig',
        component_BasicEditerV3_data_previewTypeList_name_4: 'Preview',
        component_BasicEditerV3_method_sourceTagEvent_message_1: 'Cursor must be in the target text box',
        component_BasicEditerV3_method_sourceTagEvent_message_2:
            'The copied source text tags do not match the target text box',
        component_BasicEditerV3_method_sourceTagEvent_message_3: 'Target text cannot be empty',
        component_BasicEditerV3_method_saveQuicklyAddTermEvent_message_1: 'Term source text cannot be empty',
        component_BasicEditerV3_method_saveQuicklyAddTermEvent_message_2: 'Term target text cannot be empty',
        component_BasicEditerV3_method_saveQuicklyAddTermEvent_message_3: 'Term saved successfully',
        component_BasicEditerV3_method_getSentSourceText_message_1: 'Repeated Segment',
        component_BasicEditerV3_method_getSentSourceText_message_2: 'Translation Memory',
        component_BasicEditerV3_method_getSentSourceText_message_3: 'Machine Translation',
        component_BasicEditerV3_method_getSentSourceText_message_4: 'Non-translatable element',
        component_BasicEditerV3_method_getSentSourceText_message_5: 'From {names}',
        component_CommentRight_title: 'Comments',
        component_CommentRight_tips_1: 'Close',
        component_CommentRight_empty_message: 'No comments yet',
        component_CommentRight_input_placeholder: 'Enter comment',
        component_CommentRight_btn_1: 'Cancel',
        component_CommentRight_btn_2: 'Submit',
        component_CommentRight_tips_2: 'Press Enter to send',
        component_CommentRight_data_alertInfo_message: 'Are you sure you want to delete this comment?',
        component_CommentRight_data_alertInfo_buttonName: 'Confirm',
        component_CommentRight_method_sendNewMessageEvent_message_1:
            'Please select a segment before submitting a comment',
        component_CommentRight_method_sendNewMessageEvent_message_2: 'Please enter comment content',
        component_CommentRight_method_submitDeleteBtnEvent_message_1: 'Comment deleted',
        component_EditerRight_tips_1: 'Search in all my memories/termbases',
        component_EditerRight_tips_2: 'Searching...',
        component_EditerRight_tips_3: 'Click to insert at the target text cursor position',
        component_EditerRight_tips_4: 'Remarks: ',
        component_EditerRight_tips_5: 'Memory Name: ',
        component_EditerRight_tips_6: 'Creator: ',
        component_EditerRight_tips_7: 'Creation Time: ',
        component_EditerRight_tips_8: '{name} translation',
        component_EditerRight_tips_9: 'Translating...',
        component_EditerRight_btn_1: 'Apply',
        component_EditerRight_chunk_title_1: 'Termbase',
        component_EditerRight_chunk_title_2: 'Translation Memory',
        component_EditerRight_chunk_title_3: 'Machine Translation',
        component_EditerRight_chunk_title_4: 'Reference Engine',
        component_EditerRight_chunk_empty_message_1: 'No related terms found',
        component_EditerRight_chunk_empty_message_2:
            'Paragraph splitting mode does not support translation memory queries',
        component_EditerRight_chunk_empty_message_3: 'No related translation memory found',
        component_EditerRight_chunk_empty_message_4:
            'Paragraph splitting mode does not support machine translation queries',
        component_EditerRight_chunk_empty_message_5: 'No other machine translation results found',
        component_EditRecordRight_title: 'Edit History',
        component_EditRecordRight_tips_1: 'Refresh',
        component_EditRecordRight_tips_2: 'Close',
        component_EditRecordRight_empty_message: 'No edit history yet~',
        component_FindMTRight_search_input_placeholder: 'Search in all my memories/termbases',
        component_FindMTRight_tabs_item_1: 'Translation Memory',
        component_FindMTRight_tabs_item_2: 'Termbase',
        component_FindMTRight_tips_1: 'Copy translation',
        component_FindMTRight_empty_message_1: 'No content at the moment',
        component_FindMTRight_method_copyEvent_message_1: 'Copied to clipboard',
        component_FindMTRight_method_copyEvent_message_2: 'Failed to copy to clipboard, please try again',
        component_FindReplaceRight_title: 'Find/Replace',
        component_FindReplaceRight_tips_1: 'Close',
        component_FindReplaceRight_tips_2: 'Click to locate',
        component_FindReplaceRight_tips_3: 'Segment',
        component_FindReplaceRight_tips_4: 'Click to replace',
        component_FindReplaceRight_form_item_1_label: 'Find',
        component_FindReplaceRight_form_item_1_input_placeholder: 'Enter the original text or translation to find',
        component_FindReplaceRight_form_item_2_label: 'Replace with',
        component_FindReplaceRight_form_item_2_input_placeholder:
            'Enter the content to replace, can only replace translations',
        component_FindReplaceRight_form_checkbox_label: 'Case sensitive',
        component_FindReplaceRight_btn_1: 'Find',
        component_FindReplaceRight_btn_2: 'Replace All',
        component_FindReplaceRight_btn_3: 'Undo Replace',
        component_FindReplaceRight_empty_message: 'No content at the moment',
        component_MenuActions_data_actionList_tip_1: 'Add Term',
        component_MenuActions_data_actionList_tip_2: 'Translation Memory',
        component_MenuActions_data_actionList_tip_3: 'Comment',
        component_MenuActions_data_actionList_tip_4: 'Bold',
        component_MenuActions_data_actionList_tip_5: 'Italic',
        component_MenuActions_data_actionList_tip_6: 'Underline',
        component_MenuActions_data_actionList_tip_7: 'Strikethrough',
        component_MenuActions_data_actionList_tip_8: 'Superscript',
        component_MenuActions_data_actionList_tip_9: 'Subscript',
        component_PreviewExcel_tips_1: '*Preview format may differ from the final format after download',
        component_PreviewPPT_tips_1: 'PPT preview is not supported',
        component_PreviewWord_tips_1: '*Preview format may differ from the final format after download',
        component_QARight_title: 'QA Check',
        component_QARight_tips_1: 'Close',
        component_QARight_tips_2: 'QA Settings',
        component_QARight_tips_3: 'Download Check Results',
        component_QARight_tips_4: 'Ignore',
        component_QARight_tips_5: 'Operator: ',
        component_QARight_tips_6: 'Cancel Ignore',
        component_QARight_tabs_item_1: 'All',
        component_QARight_tabs_item_2: 'View QA Ignore History',
        component_QARight_empty_message_1: 'Keep up the good work~ No quality issues found in confirmed segments',
        component_QARight_empty_message_2: 'No ignored quality issues at the moment',
        component_QARight_method_downloadQAResult_title: '{name} QA Report',
        component_ReferenceEngine_dialog_title: 'Change Reference Engine',
        component_ReferenceEngine_dialog_btn_1: 'Cancel',
        component_ReferenceEngine_dialog_btn_2: 'Confirm',
    },
};
