<template> 
    <div class="download-notice-components" :title="$t('component.DownloadNotices_drag_tips')" id="download-notice-hander" v-drag="'#download-notice-hander'">
        <template v-if="notices.length > 0">
            <template v-for="(notice, index) in notices">
                <transition name="notice-item-fade"
                    leave-active-class="animated fadeOutRight faster"
                    @after-leave="onAfterLeave(index)"
                    :key="index">
                    <div class="notice-item" v-show="notice.show">
                        <div class="close" @click="onClose(index)" @touchend="onClose(index)">
                            <i class="el-icon-close"></i>
                        </div>
                        <div class="status">
                            <div :class="[notice.status === 'S' ? 'success' : notice.status === 'F' ? 'error' : 'creating']">
                                <template v-if="notice.status === 'I'">
                                    <div class="status-icon"><i class="el-icon-loading"></i></div>
                                    <div class="fonts-12 margin-t-10">{{notice.statusDesc}}</div>
                                </template>
                                <template v-if="notice.status === 'C'">
                                    <div class="status-icon"><i class="el-icon-loading"></i></div>
                                    <div class="fonts-12 margin-t-10">{{ $t('component.DownloadNotices_status_name_1') }}</div>
                                </template>
                                <template v-if="notice.status === 'S'">
                                    <div class="status-icon"><i class="el-icon-success"></i></div>
                                    <div class="fonts-12 margin-t-10">{{ $t('component.DownloadNotices_status_name_2') }}</div>
                                </template>
                                <template v-if="notice.status === 'F'">
                                    <div class="status-icon"><i class="el-icon-error"></i></div>
                                    <div class="fonts-12 margin-t-10">{{ $t('component.DownloadNotices_status_name_3') }}{{notice.failureCause? '，'+notice.failureCause : ''}}</div>
                                </template>
                            </div>
                            <div class="name">{{ notice.title }}</div>
                            <div class="link" v-if="notice.status === 'S'"><span @click="onDownload(notice, index)" @touchend="onDownload(notice, index)">{{ $t('component.DownloadNotices_button_download') }}</span></div>
                        </div>
                    </div>
                </transition>
            </template>
        </template>
    </div>
</template>
<script>
import { debounce } from 'lodash';
import { commonApi } from '@/utils/api';
import { mapState } from 'vuex';
import fileDownload from 'js-file-download';
export default {
    name: 'DownloadNotice',
    data(){
        return {
            timer: null,
        }
    },
    computed: {
        ...mapState({
            notices: state => state.download.notices,
        }),
    },
    methods: {
        startTimer(){
            let self = this;
            this.timer = setInterval(() => {
                self.$store.dispatch('download/getNoticeList');
            }, 5000);
        },
        onClose(index, down){
            this.$store.dispatch('download/hideNotice', index);
            if(down !== true){
                this.deleteRemoteNotice(index);
            }
        },
        onDownload: debounce(function(notice, index){
            let url = `${commonApi.getDownloadUrl}?resourceId=${notice.resourceId}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    if(res.data && res.data.downloadResource && res.data.downloadResource.downloadUrl){
                        window.open(res.data.downloadResource.downloadUrl, '_self');
                        this.onClose(index, true);
                    } else {
                        this.$message.error(this.$t('component.DownloadNotices_download_link_fail_message'));
                    }
                }
            })
        }),
        downloadFileEvent(url, name){
            this.$ajax.axios.get(url,{responseType:'blob'}).then(res=>{
                fileDownload(res, name);
            })
        },
        addNotice(notice){
            let x = {
                ...notice,
                "resourceId": '1016728037140001' + Math.floor(Math.random()*1000),
                "title": "项目文档 - 《红楼梦故事纷杂《红楼梦故事纷杂《红楼梦故事纷杂.txt"+new Date().getTime(),
                "status": "C",
                "statusDesc": "成功",
                "failureCause": "红楼梦故事纷杂",
                "createTime": "2023-01-04 11:41:54",
                show: true,
            }
            this.$store.dispatch('download/createNotice', x);
            /* this.$nextTick(()=>{
                this.$store.dispatch('download/showNotice', 0);
            }) */
        },
        onAfterLeave(index){
            this.$store.dispatch('download/deleteNotice', index);
        },
        deleteRemoteNotice(index){
            let url = commonApi.deleteDownloadItem;
            let resourceId = this.notices[index].resourceId;
            this.$ajax.post(url, {resourceId}).then(res => {
                if(res.status === 200){
                    console.info('[INFO]', this.$t('component.DownloadNotices_download_delete_remote_file_success'));
                }
            });
        }
    },
    mounted() {
        
    },
    destroyed() {
        clearInterval(this.timer);
        this.timer = null;
    },
    watch: {
        '$route'(to, from){
            if (to.name === 'Login'){
                clearInterval(this.timer);
                this.timer = null;
            }
        },
        notices(newVal, oldVal){
            if(newVal.length > 0){
                if(this.timer === null){
                    this.startTimer();
                }
            }else{
                if(this.timer){
                    clearInterval(this.timer);
                    this.timer = null;
                }
            }
        }

    }
}
</script>
<style lang="scss" scoped>
.download-notice-components{
    position: fixed;
    z-index: 100;
    width: 350px;
    // height: 500px;
    top: 56px;
    // right: 20px;
    left: 50%;
    margin-left: -150px;
    user-select: none;
    .move-hander{
        height: 20px;
        background-color: #00000021;
        user-select: none;
        font-size: 12px;
        color: #ffffff;
        text-align: center;
        line-height: 20px;
        margin-bottom: 2px;
        border-radius: 4px;
    }
    .notice-item{
        text-align: center;
        position: relative;
        padding: 30px 20px;
        background-color: #FFFFFF;
        box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        margin-bottom: 4px;
        .name {
            flex: 1;
            font-size: 12px;
            margin-top: 10px;
            overflow:hidden;
            white-space:nowrap;
            text-overflow:ellipsis;
        }
        .status{
            .status-icon{
                font-size: 16px;
            }
            .creating{
                color: #2979FF;
            }
            .success {
                color: #00B670;
            }
            .error {
                 color: #F56C6C;   
            }
            .link {
                font-size: 13px;
                color: #2979FF;
                margin-top: 10px;
                cursor: pointer;
            }
        }
        .btn {
            margin-top: 10px;
        }
        .close{
            position: absolute;
            font-size: 14px;
            top: 0;
            right: 0;
            padding: 10px;
            cursor: pointer;
        }
       
    }
}
</style>
