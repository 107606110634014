import ajax from '@/utils/ajax';
import { commonApi } from '@/utils/api';
const state = () => ({
    notices: [],
})

const getters = {

}

const actions = {
    createNotice({commit}, data){
        commit('CREATE_NOTICE',data);
    },
    createNotices({commit}, data){
        commit('CREATE_NOTICES',data);
    },
    deleteNotice({commit}, data){
        commit('DELETE_NOTICE', data);
    },
    showNotice( {commit}, data){
        commit('SHOW_NOTICE', data);
    },
    hideNotice( {commit}, data){
        commit('HIDE_NOTICE', data);
    },
    getNoticeList({commit}, data){
        commit('GET_NOTICE_LIST', data);
    },
}

const mutations = {
    CREATE_NOTICE(state, payload){
        state.notices.unshift(payload);
    },
    CREATE_NOTICES(state, payload){
        state.notices = payload.concat(state.notices);
    },
    DELETE_NOTICE(state, payload){
        state.notices.splice(payload, 1);
    },
    SHOW_NOTICE(state, payload){
        state.notices[0].show = true;
    },
    HIDE_NOTICE(state, payload){
        state.notices[payload].show = false;
    },
    GET_NOTICE_LIST(state, payload){
        ajax.get(commonApi.queryDownloadTaskList).then(res => {
            if(res.status === 200){
                let list = res.data;
                let _notices = state.notices;
                _notices.map((notice, index) => {
                    for (let item of list){
                        if (notice.resourceId === item.resourceId){
                            state.notices.splice(index, 1, {...notice, ...item});
                            break;
                        }
                    }
                });
            }
        });
    }
}

export default{
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
