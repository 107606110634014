<template>
    <el-dialog :title="$t('component.PayErpDialog_title')" :visible.sync="payDialog.show" destroy-on-close
        @open="openPayDialogEvent" @close="closePayDialogEvent" width="872px" top="20px">
        <div class="pay-erp-success" v-if="status === 'paid'">
            <i class="el-icon-success"></i>
            <div class="fonts-20 margin-t-10">{{ $t('component.PayErpDialog_success_message_1') }}</div>
            <div class="fonts-14 margin-t-10">{{ $t('component.PayErpDialog_success_message_2') }}</div>
            <img src="../../static/images/tencent-group-qrcode.png" alt="qrcode" />
            <div class="fonts-14 margin-t-10">{{ $t('component.PayErpDialog_success_message_3') }}</div>
            <div class="text-center margin-t-30">
                <el-button plain round size="small" @click="applyInvoiceEvent"><svg-icon name="ib-invoicing"></svg-icon>
                    {{ $t('component.PayErpDialog_button_apply_invoice') }}</el-button>
            </div>
        </div>
        <div class="pay-erp-dialog" v-loading="loading" v-else>
            <div class="pay-type-content">
                <template v-for="item in priceOptions">
                    <div class="item" :class="{'active': active === item.code}" :key="item.code"
                        @click="changePayTypeEvent(item)">
                        <div class="text-weight-600 fonts-18 margin-b-10">{{item.desc}}</div>
                        <div class="fonts-14 color-919191 margin-b-20">{{item.subDesc}}</div>
                        <div class="fonts-28">
                            <span
                                class="text-weight-600">{{$t('component.PayErpDialog_currency_symbol')}}{{item.price}}/</span><span
                                class="fonts-16 text-weight-700">{{item.timeUnit}}</span>
                            <br>
                            <span class="fonts-18 color-919191 line-through margin-l-5">¥{{item.originalPrice}}/{{item.timeUnit}}</span>
                        </div>
                        <!-- <div class="tag">限时</div> -->
                    </div>
                </template>
            </div>

            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="padding-20 border-default radius-12">
                        <div class="flex flex-align-center margin-b-20">
                            <div style="width: 88px;">{{ $t('component.PayErpDialog_form_orgName_label') }}</div>
                            <div class="flex-1 margin-l-20">
                                <el-autocomplete v-model="orgName" size="medium" @input="handleOrgNameInput"
                                    :fetch-suggestions="queryOrgNameList" @select="handleOrgNameSelect"
                                    style="width: 100%;"
                                    :placeholder="$t('component.PayErpDialog_form_orgName_placeholder')">
                                    <template slot-scope="{ item }">
                                        <div class="padding-y-10">
                                            <div class="line-height-1">{{ item.fullName }}</div>
                                            <div class="fonts-12 color-919191 line-height-1 margin-t-5">{{
                                                item.shortName }}</div>
                                        </div>
                                    </template>
                                </el-autocomplete>

                            </div>
                        </div>
                        <div class="flex flex-align-center margin-b-20">
                            <div style="width: 88px;">{{ $t('component.PayErpDialog_form_startTime_label') }}</div>
                            <div class="flex-1 margin-l-20">
                                <el-date-picker v-model="startTime" @change="changeStartTimeEvent" size="medium"
                                    type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width: 100%;"
                                    :placeholder="$t('component.PayErpDialog_form_startTime_placeholder')"></el-date-picker>
                            </div>
                        </div>
                        <div class="flex flex-align-center margin-b-20">
                            <div style="width: 88px;">{{ $t('component.PayErpDialog_form_endTime_label') }}</div>
                            <div class="flex-1 margin-l-20">
                                <el-input v-model="endTime" prefix-icon="el-icon-date" readonly size="medium"
                                    style="width: 100%;"
                                    :placeholder="$t('component.PayErpDialog_form_endTime_placeholder')"></el-input>
                            </div>
                        </div>

                        <div class="flex flex-align-center flex-justify-center">
                            <el-button type="primary" size="medium" :loading="generatingContractBtnLoading" round
                                @click="generatingContractEvent">{{ $t('component.PayErpDialog_form_button_cofirm')
                                }}</el-button>
                        </div>
                        <template v-if="userInfo.organizationType !== 'PERSON'">
                            <div class="fonts-12 margin-t-10 color-999 text-center">{{
                                $t('component.PayErpDialog_form_orgName_tips_1') }}<a href="/account/orgInfo"
                                    @click="closePayDialogEvent">{{ $t('component.PayErpDialog_form_orgName_tips_2')
                                    }}</a></div>
                        </template>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="pay-detail-content" style="height: 244px;">
                        <template v-if="contractUrl">
                            <template v-if="order">
                                <div class="qrcode">
                                    <img class="width-full" :src="order.codeImgUrl" alt="qrcode" />
                                </div>
                                <div class="fonts-12" style="padding-left:20px;">
                                    <div>{{ $t('component.PayErpDialog_form_orderNo_label') }}<span
                                            class="margin-l-10">{{order.orderNo}}</span></div>
                                    <div class="margin-t-10">{{ $t('component.PayErpDialog_form_amount_label') }}<span
                                            class="margin-l-10 fonts-24 text-weight-600">{{$t('component.PayErpDialog_currency_symbol')}}
                                            {{order.amount}}</span></div>
                                    <div class="margin-t-10 flex flex-align-center">{{
                                        $t('component.PayErpDialog_form_scan_label') }}<svg-icon name="ic-wechat-pay"
                                            className="margin-l-10" style="width: 22px;height: 22px;"></svg-icon><span
                                            class="margin-l-5">{{ $t('component.PayErpDialog_form_scan_platform_wechat')
                                            }}</span></div>
                                    <div class="margin-t-10" @click="closePayDialogEvent"><router-link
                                            to="/account/vip">{{ $t('component.PayErpDialog_form_org_benefits_tips')
                                            }}</router-link></div>
                                </div>
                            </template>
                        </template>
                        <div class="mask-cover" v-else>{{ $t('component.PayErpDialog_form_cofirm_tips_1') }}<br>{{
                            $t('component.PayErpDialog_form_cofirm_tips_2') }}</div>
                    </div>
                </el-col>
            </el-row>
            <div class="fonts-12 margin-t-25 text-center">{{ $t('component.PayErpDialog_bottom_tips_1') }} <a
                    href="http://static.languagex.com/page/serviceTerms.html" target="_blank">{{
                    $t('component.PayErpDialog_bottom_tips_2') }}</a> {{ $t('component.PayErpDialog_bottom_tips_3') }}
                <a href="http://static.languagex.com/page/privacyPolicy.html" target="_blank">{{
                    $t('component.PayErpDialog_bottom_tips_4') }}</a></div>
        </div>

    </el-dialog>
</template>
<script>
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';
import { userApi, payApi } from '@/utils/api';
export default {
    data () {
        return {
            priceOptions: [],
            active: '', //yearly
            order: null,
            loading: false,
            orderStatusTimer: null,
            status: '',
            startTime: '',
            endTime: '',
            orgId: '',
            orgName: '',
            contractUrl: '',
            generatingContractBtnLoading: false,
        }   
    },
    computed: {
        ...mapState({
            payDialog: state => state.pay.payErpDialog,
            userInfo: state => state.user.userData,
        })
    },
    
    methods: {
        initPriceOptions(){
            let url = `${payApi.queryPriceOptions}?type=ENTERPRISE`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.priceOptions = res.data;
                    this.active = this.priceOptions[0].code;
                    this.startTime = moment(new Date()).format('yyyy-MM-DD');
                    this.queryEndTime();
                    // this.initOrder();
                }
            });
        },
        closePayDialogEvent(){
            this.active = '';
            this.status = '';
            this.contractUrl = '';
            this.$store.dispatch('pay/setPayErpDialogAction',{show: false});
            clearInterval(this.orderStatusTimer);
            this.orderStatusTimer = null;
        },
        openPayDialogEvent(){
            this.initPriceOptions();
        },
        changeStartTimeEvent(val){
            this.queryEndTime();
        },
        queryEndTime(){
            let url = payApi.queryErpExpiredData;
            this.$ajax.post(url,{paymentMethod: this.active, start: this.startTime, orgId: this.orgId}).then(res=>{
                if(res.status === 200){
                    this.endTime = res.data.end;
                    this.startTime = res.data.start;
                }
            })
        },
        initOrder(){
            let self = this;
            let url = `${payApi.createOrder}`;
            this.loading = true;
            this.$ajax.post(url,{
                method: this.active,
                channel: 'wechat',
                orgName: this.orgName,
                orgId: this.orgId,
            }).then(res=>{
                if(res.status === 200){
                    this.order = res.data;
                    if(this.orderStatusTimer){
                        clearInterval(this.orderStatusTimer);
                        this.orderStatusTimer = null;
                    }
                    this.orderStatusTimer = setInterval(() =>{
                        self.pollingOrderStatus();
                    },1000);
                }
            }).finally(()=>{
                this.loading = false;
            })
        },
        changePayTypeEvent(item){
            this.contractUrl = '';
            this.active = item.code;
            this.queryEndTime();
        },
        pollingOrderStatus(){
            let url = `${payApi.queryOrder}?orderNo=${this.order.orderNo}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    let data = res.data;
                    if(data.orderStatus === 'paid'){
                        this.status = data.orderStatus;
                        // this.$message.success("支付成功!");
                        clearInterval(this.orderStatusTimer);
                        this.orderStatusTimer = null;
                        //用户信息
                        this.$store.dispatch('user/queryUserInfoAction');
                        //我的机构信息
                        this.$store.dispatch('org/queryMyOrgList');
                    }else if(data.orderStatus === 'failed'){
                        this.$message.success(this.$t('component.PayErpDialog_pay_fail_message'));
                        this.initOrder();
                    }
                }
            })
        },
        applyInvoiceEvent() {
            this.closePayDialogEvent();
            window.open('https://m15er90ta0.feishu.cn/share/base/form/shrcnt4Z1ziC7IVeP7AuTq8ffDc', '_blank');
        },
        generatingContractEvent() {
            if(!this.orgName) {
                this.$message.error(this.$t('component.PayErpDialog_orgName_verification_message'));
                return;
            }
            if(this.orgName.startsWith('LXO')){
                this.$message.error(this.$t('component.PayErpDialog_orgName_LOX_verification_message'));
                return;
            }
            this.generatingContractBtnLoading = true;
            let url = payApi.generatingContract;
            this.$ajax.post(url, {method: this.active, orgName: this.orgName}).then(res => {
                if (res.status === 200) {
                    this.contractUrl = res.data;
                    this.initOrder();
                    window.open(this.contractUrl, '_blank');
                }
            }).finally(() => {
                this.generatingContractBtnLoading = false;
            })

        },
        queryOrgNameList(keyword, cb) {
            let url = payApi.queryManageOrg;
            this.$ajax.get(url).then(res => {
                if (res.status === 200) {
                    cb(res.data);
                }
            })
        },
        handleOrgNameSelect(item) {
            this.orgName = item.fullName;
            this.orgId = item.orgId;
            this.queryEndTime();
        },
        handleOrgNameInput(val) {
            this.orgId = '';
        }
    },
    mounted(){
        window.addEventListener('beforeunload', e => {
            this.closePayDialogEvent();
        });
    },
    beforeDestroy(){
        window.removeEventListener('beforeunload');
    }
    
}
</script>
<style lang="scss" scoped>
.pay-erp-dialog {
    margin: 10px 0;
    min-height: 326px;
    .pay-type-content{
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        .item{
            position: relative;
            padding: 20px;
            width: 250px;
            margin-right: 20px;
            border: 2px solid transparent;
            box-sizing: border-box;
            border-radius: 12px;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
            cursor: pointer;
            &:last-child{
                margin-right: 0;
            }
            &.active{
                border-color: #00D959;
            }
            .tag{
                border-radius: 0 12px 0 12px;
                background-color: #F56C6C;
                width: 48px;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 500;
                font-size: 12px;
                color: #FFFFFF;
                position: absolute;
                top: -2px;
                right: -2px;
            }
        }
    }
    .pay-detail-content{
        position: relative;
        height: 132px;
        padding: 0 20px;
        margin: 0 auto;
        background-color: #FFFFFF;
        border: 1px solid #E4E4E4;
        border-radius: 12px;
        display: flex;
        align-items: center;
        // justify-content: center;
        .qrcode{
            width: 120px;
            height: 120px;
            position: relative;
        }
        .mask-cover {
            position: absolute;
            border-radius: 12px;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            font-size: 13px;
            display: flex;
            align-items: center;
            text-align: center;
            line-height: 1.5;
            justify-content: center;
        }
    }
}
.pay-erp-success{
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    i {
        font-size: 44px;
        color: #00B670;
    }
    img {
        margin-top: 40px;
        width: 140px;
    }

}
</style>
