import Vue from 'vue';
// import router from '@/router';
import cookie from 'vue-cookies';
import { userApi } from '@/utils/api';
import ajax from '@/utils/ajax';

const state = ()=> ({
    userData: {},
    permissions: {all: [], router: [], action: [], data: []},
    // accountData: {},
    showBindWechat: false,
})

const getters = {
    loginStatus: state =>{
        let userInfo = localStorage.getItem('userInfo');
        let token = cookie.get('token');
        let is = token && userInfo ? true : false;
        return is;
    },
    iconName: state =>{
        return state.userInfo && state.userInfo.username ? state.userInfo.username.charAt(0).toUpperCase() : '' ;
    },
    userInfo: state => JSON.parse(localStorage.getItem('userInfo'))
}

const actions = {
    changeLoginStatusAction({commit}, data){
        commit('CHANGE_LOGIN_STATUS', data);
    },
    initUserInfoAction({commit}, data){
        commit('INIT_USER_INFO', data);
    },
    queryUserInfoAction({commit}, id){
        return new Promise(async (resolve, reject) => {
            try {
                let url = userApi.queryUserInfo;
                let userRes = await ajax.get(url);
                let accountRes = await ajax.get(userApi.queryAccountInfo);
                
                let user = {};
                if (userRes.status === 200){
                    user = { ...user, ...userRes.data };
                }
                if (accountRes.status === 200){
                    user = { 
                        ...user, 
                        ...accountRes.data,
                        featureConfig: {
                            ...user.featureConfig,
                            ...accountRes.data.fileLimit,
                        }
                    };
                }
                commit('QUERY_USER_INFO', {user, type: id});
                resolve(user);
            }catch(err){
                reject(err);
            }
        })
    },
    setBindWechatVisible({commit}, data){
        commit('SET_BINDWECHAT_VISIBLE', data)
    },
    initUserPermissions({commit}, data){
        return new Promise((resolve, reject) => {
            ajax.post(userApi.queryPermissionList, {}).then(res => {
                if(res.status === 200) {
                    // state.permissions = res.data;
                    let allPermissionKeys = [];
                    let routerPermissionKeys = [];
                    let actionPermissionKeys = [];
                    res.data.map(item => {
                        allPermissionKeys.push(item.permission);
                        if(item.menuType === 1){
                            routerPermissionKeys.push(item.permission);
                        }
                        if(item.menuType === 2){
                            actionPermissionKeys.push(item.permission);
                        }
                    });
                    const permissions = {
                        all: allPermissionKeys,
                        router: routerPermissionKeys,
                        action: actionPermissionKeys,
                        data: res.data,
                    };
                    commit('INIT_USER_PERMISSIONS', permissions);
                    resolve({
                        all: allPermissionKeys,
                        router: routerPermissionKeys,
                        action: actionPermissionKeys,
                        data: res.data,
                    });
                }
            }).catch(err => {
                reject(err);
            });
        });
        
    },
}

const mutations = {
    CHANGE_LOGIN_STATUS(state, payload){      
        state.loginStatus = payload.status;
    },
    INIT_USER_INFO(state, payload){
        if(payload.info){
            localStorage.setItem('userInfo', JSON.stringify(payload.info));
        }else{
            localStorage.removeItem('userInfo');
            cookie.remove('token');
        }
    },
    QUERY_USER_INFO: (state, payload)=>{
        const {user, type} = payload;
        state.userData = user;
        localStorage.setItem('userInfo', JSON.stringify(user));
        if(user.popWindows === true && type !== 'login'){
            state.showBindWechat = true;
        }
    },
    INIT_USER_PERMISSIONS(state, payload) {
        state.permissions = payload;
    },
    SET_BINDWECHAT_VISIBLE(state, payload){
        state.showBindWechat = payload;
    }
}

export default{
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
