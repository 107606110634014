import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import drag from "v-drag"
import VueCookies from 'vue-cookies';
import Storage from 'vue-ls';
import ajax from './utils/ajax';

import VueScrollTo from 'vue-scrollto';
import VueClipboard from 'vue-clipboard2';
import Contextmenu from "vue-contextmenujs";
import { shortcut } from './utils/shortcut';
import VueTimeago from 'vue-timeago';
import { Permission } from './components/permission';

import {i18n} from './assets/i18n/index';
import './assets/css/element-variables.scss';
import './assets/css/common.scss';
import './assets/icons';
Vue.use(VueCookies);
Vue.use(Permission);
Vue.use(drag);
Vue.use(VueScrollTo);
Vue.use(VueClipboard);
Vue.use(Contextmenu);
const options = {
  namespace: 'LanguageX__ls__',
  name: 'ls', 
  storage: 'local',
};
Vue.use(Storage, options);
//获取当前系统语言
// console.log("language::",navigator.language);

Vue.prototype.$ajax = ajax;
Vue.prototype.$shortcut = shortcut; //快捷键组件

Vue.use(VueTimeago, {
    locale: 'zh-CN',
    locales: {
        'zh-CN': require('date-fns/locale/zh_cn'),
        'en': require('date-fns/locale/en'),
    }
});

const noLimitPath = ['/login','/signup','/forget'];
router.beforeEach((to, from, next) => {
    try{
        let isLogin = VueCookies.get('token') !== null && localStorage.getItem('userInfo') !== null;
        const title = to.meta && to.meta.title;
        if (title) {
            document.title = 'LanguageX - ' + title;
        }
        if (isLogin){
            next();
        }else if(noLimitPath.indexOf(to.path) > -1){
            next();
        } else {
            localStorage.setItem('redirect',location.href);
            next({
                replace: true,
                path:'/login'
            });
        }
    } catch (e) {
        console.log(e);
    }
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
