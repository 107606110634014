<template>
	<div id="app">
		<el-container>
			<el-aside :width="showAside?'124px':'0px'" v-if="showAside">
				<left-aside></left-aside>
			</el-aside>
            <el-container>
                <el-header height="46px" v-if="noneHeaderRouters.indexOf($route.name) < 0">
                    <page-header></page-header>
                </el-header><!-- :style="noneHeaderRouters.indexOf($route.name) < 0?'padding-top: 0;':''" -->
                <el-main :class="[$route.name == 'Editer'? 'editer': noneAsideRouters.indexOf($route.name) > -1? 'login': '']" >
                    <common-component></common-component>
                    <div class="wrapper">
                        <!-- <breadcrumb></breadcrumb> -->
                        <div class="main">
                            <!-- <transition name="el-zoom-in-bottom" :duration="100"> -->
                            <keep-alive>
                                <router-view v-if="$route.mata && $route.mata.keepAlive" />
                            </keep-alive>
                            <router-view v-if="$route.mata && $route.mata.keepAlive"/>
                            <router-view v-else />
                            <!-- </transition> -->
                        </div>
                    </div>
                </el-main>
            </el-container>
		</el-container>
	</div>
</template>
<script>
import LeftAside from "./components/LeftAside";
import PageHeader from './components/PageHeader';
import Breadcrumb from "./components/Breadcrumb";
import { mapGetters } from "vuex";
import CommonComponent from './components/CommonComponent.vue';

export default {
	data() {
		return {
			showAside: false,
			noneAsideRouters: ["Editer", "Login", "Signup", "Forget", ],
            noneHeaderRouters: ["Editer", "Login", "Signup", "Forget", ] //"TermDetail", "MemoryDetail", "ToolsAlignDetail", "ToolsAlignDetailTiled", "ToolsTermExtractionDetail", "ToolsQADetail", "ToolsDetectorDetail",
		};
	},
	components: {
		LeftAside,
        PageHeader,
		Breadcrumb,
        CommonComponent,
	},
	computed: {
		...mapGetters({
			isLogin: "user/loginStatus",
		}),
	},
	created() {
        if (this.noneAsideRouters.indexOf(this.$route.name) > -1) {
			this.showAside = false;
		} else {
			this.showAside = true;
		}
        
	},
	mounted() {
		// console.log('环境变量：',process.env)
	},
	watch: {
		$route(to, from) {
            // console.log(this.$route);
			if (this.noneAsideRouters.indexOf(to.name) > -1) {
				this.showAside = false;
			} else {
				this.showAside = true;
			}
            if (this.isLogin){
                if (to.name === "Login"){
                    this.$router.replace(from.path);
                }
            }
		}, 
	},
};
</script>
<style lang="scss">

</style>
