<template>
    <span v-if="userInfo">
        <svg-icon name="ic-pro" :style="`font-size:${size}px`" v-if="['pro-trial', 'pro-internal', 'pro', 'pro-beta', 'internal'].indexOf(accountType)>-1"></svg-icon>
        <svg-icon name="ic-free" :style="`font-size:${size}px`" v-if="['personal', 'pro-limited'].indexOf(accountType)>-1"></svg-icon>
        <svg-icon name="ic-enterprise" :style="`font-size:${size}px`" v-if="['enterprise', 'STANDARD', 'TRIAL'].indexOf(accountType)>-1"></svg-icon>
        <svg-icon name="ic-ultimate" :style="`font-size:${size}px`" v-if="['ULTIMATE'].indexOf(accountType)>-1"></svg-icon>
    </span>
</template>
<script>
import { mapState } from 'vuex';
export default {
    name: 'VipIcon',
    components: {  },
    props: {
        size: {
            type: Number,
            default: 22,
        },
        type: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            accountType: 'personal',
        }
    },
    computed: {
        ...mapState({
			userInfo: state => state.user.userData,
		}),
    },
    created(){
        this.accountType = this.type? this.type: this.userInfo.accountType;
    },
    watch: {
        type(newVal, oldVal) {
            this.accountType = newVal ? newVal : this.userInfo.accountType;
        }
    }
    
}
</script>
