import ajax from '@/utils/ajax';
import { docApi } from '@/utils/api';

const state=()=>({
    editerRightData:null,
})

const getters={

}

const actions ={
    initEditerRightAction({commit},data){
        commit('INIT_EDITER_RIGHT',data);
    },
    
}

const mutations = {
    INIT_EDITER_RIGHT(state,payload){
        /* ajax.get(docApi.queryPassOrderSize).then(res=>{
            if(res.result_code === 'success'){
                state.editerRightData=res.data;
            }
        }); */
        
    }
}

export default{
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
